import React from "react";
import { useLocation } from "react-router-dom";
import BiologyMain from "./biology/BiologyMain";
import AiMain from "./ai/AiMain";
import FictionMain from "./fiction/FictionMain";
import HumorMain from "./humor/HumorMain";
import UsersMain from "./users/UsersMain";

const MainTable = ({ origin }) => {
  const loc = useLocation();
  let currUrl = loc.pathname;
  //if we are on the admin landing page, set url to /controller/biology
  if (currUrl === "/controller") {
    currUrl = "/controller/biology";
  }
  //console.log(currUrl);

  return (
    <>
      {origin === "users" ? (
        <UsersMain currUrl={currUrl} />
      ) : origin === "biology" ? (
        <BiologyMain currUrl={currUrl} />
      ) : origin === "ai" ? (
        <AiMain currUrl={currUrl} />
      ) : origin === "fiction" ? (
        <FictionMain currUrl={currUrl} />
      ) : origin === "humor" ? (
        <HumorMain currUrl={currUrl} />
      ) : (
        ""
      )}
    </>
  );
};

export default MainTable;
