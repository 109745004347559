import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const AiHistory = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/controllerapi/ai/history/${id}`
        );
        setRows(resp.data);
        getCols(resp.data);
        //console.log(resp.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getCols = (rows) => {
      let cols = [];

      let rowkeys = Object.keys(rows[0]);
      //console.log(rowkeys);
      for (let i = 0; i < rowkeys.length; i++) {
        let keyname = rowkeys[i];
        cols[i] = {
          field: keyname,
          headerName: keyname.toUpperCase(),
          //width: 150,
          flex: 0.4,
          minWidth: 20,
        };
      }

      setColumns(cols);
    };
    fetchData();
  }, []);

  return (
    <>
      <Box display="flex" width={"80vw"}>
        <Stack direction={"column"} width={"100%"}>
          <Typography
            m={2}
            variant="h6"
            justifyContent={"center"}
            align="center"
          >
            Modification history for post
          </Typography>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.history_id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            options={{
              responsive: "scroll",
            }}
            pageSizeOptions={[5, 10, 15, 20, 30, 50]}
            checkboxSelection
          />
        </Stack>
      </Box>
    </>
  );
};

export default AiHistory;
