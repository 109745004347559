import { Avatar, Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import Rightbar from "../rightbar/Rightbar";
import profile from "../../static/avatar_new.jpg";

const About = () => {
  return (
    <Box sx={{ padding: 3, display: { xs: "block", md: "flex" } }}>
      <Box justifyContent={"center"} align={"center"}>
        <CardMedia
          //flex={1}
          component="Avatar"
          justifyContent={"center"}
          align={"center"}
          sx={{ backgroundPosition: "center" }}
        >
          <Avatar
            justifyContent={"center"}
            align={"center"}
            position={"center"}
            src={profile}
            sx={{ height: 300, width: 300, mt: { xs: 0, md: 3 } }}
          />
        </CardMedia>
      </Box>

      <Box display={"flex"} flex={3} mt={1} sx={{ padding: 3 }}>
        <Typography
          variant="body2"
          justifyContent={"center"}
          //align="left"
          //align={{ xs: "center", md: "left" }}
          sx={{ opacity: 0.8, textAlign: { xs: "center", md: "left" } }}
          //color="#5ba7d5"
        >
          Hi, I'm Preethi P., PhD. <br /> <br />
          I have a myriad of interests. I like to explore the mystery of us
          through bioinformatics and biology, my specialties. Coding and data
          analysis interest me quite a bit. I like languages in general and have
          a soft spot for the Indian languages, English, French and Chinese. I
          enjoy drawing, illustration and writing. I also like to travel. <br />
          <br />
          I am amazed by the mystery that is the universe, and intrigued by us,
          the way our body and mind works.
          <br />
          <br />I turned full stack web developer with this website. I hope you
          like incredibleuss.com. Happy reading!
        </Typography>
      </Box>
      <Box flex={3} sx={{ display: { xs: "none", md: "flex" } }}>
        <Rightbar />
      </Box>
    </Box>
  );
};

export default About;

/*
      <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
      <Stack sx={{ padding: 3, direction: { xs: "column", md: "row" } }}>
*/
