import React from "react";
import { Link, Typography, Stack, Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  menu_science,
  menu_xs_home,
  menu_xs_others,
} from "../../utils/UsedProps";

const Footer = () => {
  const currDate = new Date().getFullYear();
  const MenuLink = styled(Link)({
    textDecoration: "none",
    cursor: "pointer",
    color: "white",
  });

  return (
    <Box
      className="menubg"
      height={"20%"}
      mt={2}
      //mr={4}
      p={1}
      sx={
        {
          //width: "100%",
          //bgcolor: "black",
        }
      }
    >
      <Container>
        <Stack direction={"row"}>
          <Stack spacing={0.5} flex={11}>
            {menu_xs_home.map((item) => {
              return (
                <MenuLink key={item.Name} href={item.url}>
                  <Typography variant="body2">{item.Name}</Typography>
                </MenuLink>
              );
            })}

            {menu_science.map((item) => {
              return (
                <Stack>
                  <Typography variant="body2" color="white" key={item.Name}>
                    {item.Name}
                  </Typography>
                  {item.submenu.map((menus) => {
                    return (
                      <MenuLink
                        p={0}
                        mt={0}
                        ml={2}
                        key={menus.Name}
                        href={menus.url}
                      >
                        <Typography variant="body2" sx={{ fontSize: "0.8em" }}>
                          <i>{menus.Name}</i>
                        </Typography>
                      </MenuLink>
                    );
                  })}
                </Stack>
              );
            })}
            {menu_xs_others.map((item) => {
              return (
                <MenuLink key={item.Name} href={item.url}>
                  <Typography variant="body2">{item.Name}</Typography>
                </MenuLink>
              );
            })}
          </Stack>
          <Box flex={1}>
            <MenuLink href="/signin">Signin</MenuLink>
          </Box>
        </Stack>
        <br />
        <Typography
          variant="body2"
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          color="white"
        >
          <i>
            {" "}
            Copyright &copy; {currDate} IncredibleUss.com. Let's explore how
            amazing we are.{" "}
          </i>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

/*
  const MenuBox = styled(Box)({
    display: "flex",
    height: "200px",
    gap: 30,
    //backgroundColor: "black",
  });
          <MenuBox flex={2} sx={{ display: { xs: "block", md: "flex" } }}>
               <MenuLink href="#">{item}</MenuLink>
*/
