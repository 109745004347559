import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../context/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  //const { email } = useContext(AuthContext);
  const { email, role } = useAuth();
  //const { isAuthenticated } = useAuth();
  const location = useLocation();
  //console.log("RequireAuth, email=", email);
  //console.log("RequireAuth, role=", role);
  //console.log("RequireAuth, allowed=", allowedRoles.includes(role));
  //return isAuthenticated ? (
  return email && allowedRoles.includes(role) ? (
    <Outlet />
  ) : (
    <Navigate
      to="/unauthorized"
      state={{ from: location, errMsg: "Access denied" }}
      replace
    />
  );
};

export default RequireAuth;

/*
 <Navigate
      to="/signin"
      state={{ from: location, errMsg: "Access denied" }}
      replace
    />
*/
