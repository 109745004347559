import { Box, MenuItem, MenuList } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";

const Sidemenu = () => {
  return (
    <Box display="flex" justifyContent={"left"} sx={{ bgcolor: "#5ba7d5" }}>
      <MenuList sx={{ color: "white" }}>
        <MenuItem component={NavLink} to="/controller/users">
          Users
        </MenuItem>
        <MenuItem component={NavLink} to="/controller/biology">
          Biology
        </MenuItem>
        <MenuItem component={NavLink} to="/controller/ai">
          AI
        </MenuItem>
        <MenuItem component={NavLink} to="/controller/fiction">
          Fiction
        </MenuItem>
        <MenuItem component={NavLink} to="/controller/humor">
          Humor
        </MenuItem>
      </MenuList>
    </Box>
  );
};

export default Sidemenu;
