import React from "react";
import {
  Card,
  CardHeader,
  Avatar,
  CardMedia,
  Link,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";

const PostscardHome = ({
  content,
  postHref,
  title,
  excerpt,
  image,
  date_posted,
}) => {
  return (
    <Box mt={3}>
      <Link href={postHref} sx={{ textDecoration: "none" }}>
        <Card
        //flexDirection="row"
        //style={{ display: { xs: "block", md: "flex" } }}
        >
          <CardHeader
            justifyContent="center"
            //textAlign="center"
            align="center"
            avatar={
              <Avatar
                //variant="rounded"
                component="string"
                aria-label={content}
                sx={{ bgcolor: "#5ba7d5", width: 80, height: 80 }}
              >
                <div>
                  <p align="center" style={{ fontSize: "0.8em" }}>
                    {content.value}
                  </p>
                </div>
              </Avatar>
            }
            title={title}
            className="genericCol"
            //title="What makes us unique? What makes us unique? What makes us unique? What makes us unique? What makes us unique? What makes us unique?"
            subheader={
              date_posted ? new Date(date_posted).toDateString() : null
              //new Date(date_posted).toDateString()
            }
            titleTypographyProps={{
              variant: "h6",
              //fontFamily: "Roberto, sans-serif",
              fontFamily: "Single Day, cursive",
            }}
          ></CardHeader>
          <Box
            //flex={6}
            mb={1}
            ml={1}
            //mt={1}
            sx={{
              display: { xs: "block", md: "flex" },
              flexDirection: "row",
            }}
          >
            <CardMedia component="Avatar">
              <Avatar
                variant="square"
                src={image}
                aria-label="post"
                sx={{
                  bgcolor: "transparent",
                  width: 300,
                  height: 300,
                  "&:hover": { opacity: 0.8, "&:hover": `all 0.5s ease` },
                  loading: "lazy",
                }}
              ></Avatar>
            </CardMedia>

            <CardContent
              justifyContent="center"
              alignItems="center"
              align="center"
              sx={{ minHeight: "150px" }}
            >
              <Typography variant="body2">{excerpt}</Typography>
              <Button
                size="small"
                href={postHref}
                //sx={{ textTransform: "none" }}
              >
                Read more...
              </Button>
            </CardContent>
          </Box>
        </Card>
      </Link>
    </Box>
  );
};

export default PostscardHome;

/*
<CardMedia component="Avatar">
            <Avatar
              variant="square"
              src={spices}
              aria-label="biology&us"
              sx={{
                bgcolor: "transparent",
                width: 300,
                height: 300,
              }}
            ></Avatar>
          </CardMedia>

<CardMedia
            image={spices}
            sx={{
              backgroundSize: "cover",
              //objectFit: "contain",
              //backgroundSize: "contain",
              height: 300,
              //width: 500,
              "&:hover": { opacity: 0.8, "&:hover": `all 0.5s ease` },
              loading: "lazy",
            }}
          />
<p
                  align="center"
                  style={{ fontSize: "0.8em" }}
                >
                  Biology
                  <br />&<br />
                  Us
                </p>
 
      <Box sx={{ height: "25vh" }}>
            </Box>

             const styles = {
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9,
      marginTop: "30",
    },
  };
*/
