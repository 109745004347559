import React from "react";
import axios from "axios";
import { Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import Postscard from "../posts_home/Postscard";
import PaginatedPosts from "../pagination/PaginatedPosts";

const PostsFiction = () => {
  const [fictionPosts, setFictionPosts] = useState([]);
  const [muiPaginateCount, setMuiPaginateCount] = useState(0);
  const pageUrlBase = "fictionapi/fictionposts";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/fictionapi/fictionposts/`
        );
        setFictionPosts(resp.data.results);
        //console.log(resp.data);
        let drfPagesizeForPagination = resp.data.results.length;
        //console.log("drfPagesizeForPagination" + drfPagesizeForPagination);
        const paginateCnt = Math.ceil(
          resp.data.count / drfPagesizeForPagination
        );
        setMuiPaginateCount(paginateCnt);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const getPagePosts = (childData) => {
    setFictionPosts(childData);
  };

  return (
    <Container justifyContent="center" align="center">
      <Grid
        container
        width="100%"
        direction={"column"}
        spacing={1}
        justifySelf={"center"}
      >
        {fictionPosts.map((fictionPost, key) => {
          return (
            <Grid item xs>
              <Postscard
                title={fictionPost.title}
                excerpt={fictionPost.summary}
                date_posted={fictionPost.date_posted}
                //image={`${process.env.REACT_APP_API_URL}/${fictionPost.image}`}
                image={fictionPost.image}
                content={{ value: ["Fiction"] }}
                postHref={`/fiction/details/${fictionPost.slug}`}
              />
            </Grid>
          );
        })}
      </Grid>

      {muiPaginateCount > 1 ? (
        <PaginatedPosts
          muiPaginateCount={muiPaginateCount}
          pageUrlBase={pageUrlBase}
          sendDataToParent={getPagePosts}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default PostsFiction;
