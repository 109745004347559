import React from "react";
import { Stack, Box } from "@mui/material";
import Sidemenu from "./Sidemenu";
import BiologyCreate from "./biology/BiologyCreate";
import AiCreate from "./ai/AiCreate";
import FictionCreate from "./fiction/FictionCreate";
import HumorCreate from "./humor/HumorCreate";
import UsersCreate from "./users/UsersCreate";

const MainCreate = ({ origin }) => {
  return (
    <Stack display="flex" flexDirection={"row"}>
      <Box flex={1}>
        <Sidemenu />
      </Box>
      <Box flex={6} m={2}>
        {origin === "users" ? (
          <UsersCreate origin={origin} />
        ) : origin === "biology" ? (
          <BiologyCreate origin={origin} />
        ) : origin === "ai" ? (
          <AiCreate origin={origin} />
        ) : origin === "fiction" ? (
          <FictionCreate origin={origin} />
        ) : origin === "humor" ? (
          <HumorCreate origin={origin} />
        ) : (
          ""
        )}
      </Box>
    </Stack>
  );
};

export default MainCreate;
