import { Box, Stack } from "@mui/material";
import React from "react";
import Rightbar from "../rightbar/Rightbar";
import Biology from "./Biology";
import AI from "./AI";
import Fiction from "./Fiction";
import Humor from "./Humor";

const MainDetails = ({ myorigin }) => {
  return (
    <Stack direction={"row"} spacing={1} m={3}>
      {myorigin === "science/biologyandus" ? (
        <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
          <Biology />
        </Box>
      ) : myorigin === "science/aiandus" ? (
        <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
          <AI />
        </Box>
      ) : myorigin === "fiction" ? (
        <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
          <Fiction />
        </Box>
      ) : myorigin === "humor" ? (
        <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
          <Humor />
        </Box>
      ) : (
        ""
      )}
      <Box flex={3} sx={{ display: { xs: "none", md: "flex" } }}>
        <Rightbar />
      </Box>
    </Stack>
  );
};

export default MainDetails;
