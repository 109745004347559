import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  MenuItem,
  InputLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { statusOptions } from "../../../utils/UsedProps";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

let changedFields = [];

const HumorView = () => {
  let { id } = useParams();

  const navigate = useNavigate();
  const loc = useLocation();
  let currUrl = loc.pathname;

  const initializeData = Object.freeze({
    title: "",
    slug: "",
    excerpt: "",
    note: "",
    status: "",
    images: [],
    like_count: 0,
    footnotes: "",
    //uploaded_images: [],
    date_posted: null,
  });

  const [formData, setFormData] = useState(initializeData);
  const [initialFormData, setInitialFormData] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/controllerapi/humor/viewdata/${id}`
        );
        setFormData(resp.data);
        //console.log(resp.data);
        setInitialFormData(resp.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const resp_images = await axios.get(
          `${process.env.REACT_APP_API_URL}/controllerapi/humor/viewimages/${id}`
        );
        //resp_images.data = resp_images.data[("id", "image", "humorpost")];
        setRows(resp_images.data);
        getCols(resp_images.data);
        //console.log("Resp_images=", resp_images.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getCols = (rows) => {
      let cols = [];

      let rowkeys = Object.keys(rows[0]);
      //console.log(rowkeys);
      for (let i = 0; i < rowkeys.length; i++) {
        let keyname = rowkeys[i];
        cols[i] = {
          field: keyname,
          headerName: keyname.toUpperCase(),
          //width: 150,
          flex: 0.4,
          minWidth: 50,
        };
      }

      let updatebutton = {
        field: "update",
        headerName: "Update",
        sortable: false,
        renderCell: ({ row }) => (
          <>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Button
                variant="contained"
                name="updateimage"
                id="updateimage"
                onClick={() => document.getElementById(row.id).click()}
              >
                Update image
              </Button>

              <input
                type="file"
                name={row.id}
                id={row.id}
                //value={formData.image}
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                required
              ></input>
            </div>
          </>
        ),
      };

      /*
      let deletebutton = {
        field: "delete",
        headerName: "Delete",
        sortable: false,
        renderCell: ({ row }) => (
          <Button
            variant="contained"
            sx={{
              "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
              //bgcolor: "#9dc6d8",
              bgcolor: "#5ba7d5",
            }}
            href={`${currUrl}/view/${row.id}`}
          >
            Delete
          </Button>
        ),
      };
      */

      cols.push(updatebutton);
      //cols.push(deletebutton);
      setColumns(cols);
    };
    fetchImageData();
  }, []);

  const handleImageChange = (e) => {
    e.preventDefault();
    const uploaded_image = e.target.files[0];
    //console.log(uploaded_image);
    const image_id = e.target.id;
    handleImageUpdate(uploaded_image, image_id);
  };

  const handleImageUpdate = async (updateimage, imageid) => {
    //console.log("Update", newimage);
    //console.log("Update", imageid);
    try {
      const resp_imageedit = await axios.put(
        `${process.env.REACT_APP_API_URL}/controllerapi/humor/editimages/${imageid}/`,
        {
          id: imageid,
          image: updateimage,
          humorpost: id,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      window.location.reload();
      //setRows(resp_imageedit.data);
      //getCols(resp_imageedit.data);
      //console.log(resp_imageedit.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddImage = async (e) => {
    e.preventDefault();
    const new_image = e.target.files[0];
    //console.log(uploaded_image);
    try {
      const resp_imageadd = await axios.post(
        `${process.env.REACT_APP_API_URL}/controllerapi/humor/editimages/`,
        {
          image: new_image,
          humorpost: id,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      window.location.reload();
      //setRows(resp_imageedit.data);
      //getCols(resp_imageedit.data);
      //console.log(resp_imageadd.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (
      initialFormData[e.target.name] !== e.target.value &&
      changedFields.indexOf(e.target.name) === -1
    ) {
      changedFields.push(e.target.name);
      //console.log("changedFields local=", changedFields);
    }
  };

  //console.log("Formdata=", formData);

  const updateData = async () => {
    //console.log("changedFields=", changedFields);
    let updatedData = {};
    changedFields.forEach((val) => {
      updatedData[val] = formData[val];
    });

    //console.log("Updated data= ", updatedData);

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/controllerapi/humor/viewdata/${id}/`,
        updatedData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      let successMsg = "Post (" + formData.title + ") successfully updated!";
      navigate("/controller/humor", { state: { successMsg: successMsg } });
    } catch (error) {
      //alert("Failed to update post!");
      setErrorMsg("Failed to update post! Please correct the errors.");
      //console.log("Response=\n", error.response.data);
      if (error.response.data !== null) {
        setErrorData(error.response.data);
      }
    }
  };

  //console.log(errorData);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateData();
    //console.log(formData);
  };

  return (
    <>
      <div className={errorMsg ? "errmsg" : "hide"}>
        <span>{errorMsg}</span>
      </div>
      <Stack display="flex" flexDirection={"row"}>
        <Box display={"flex"} flex={6} m={2} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              flex={6}
              variant="h6"
              justifyContent={"center"}
              align="center"
            >
              View and update record
            </Typography>
            <Button
              flex={2}
              variant="contained"
              sx={{
                "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                //bgcolor: "#9dc6d8",
                bgcolor: "#5ba7d5",
              }}
              href={`${currUrl}/history`}
            >
              History
            </Button>
          </Box>

          <Box
            //display={"block"}
            display={"flex"}
            spacing={1}
            m={2}
            justifyContent={"center"}
            fullWidth
          >
            <form onSubmit={handleSubmit} width="inherit">
              <div className={errorData.title ? "errdetail" : "hide"}>
                <span>{errorData.title}</span>
              </div>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                margin="normal"
                required
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <TextField
                fullWidth
                label="Slug"
                value={formData.slug}
                readonly
                margin="normal"
                required
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <div className={errorData.summary ? "errdetail" : "hide"}>
                <span>{errorData.summary}</span>
              </div>
              <TextField
                fullWidth
                label="Excerpt"
                name="excerpt"
                value={formData.excerpt}
                onChange={handleChange}
                margin="normal"
                required
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <TextField
                fullWidth
                label="Note"
                name="note"
                value={formData.note}
                onChange={handleChange}
                margin="normal"
                required
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <TextField
                fullWidth
                label="Footnotes"
                name="footnotes"
                value={formData.footnotes}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <FormControl fullWidth>
                <InputLabel id="status" InputLabelProps={{ shrink: true }}>
                  Status
                </InputLabel>
                <Select
                  //defaultValue={initialValues.status}
                  labelId="status"
                  id="status"
                  name="status"
                  value={formData.status}
                  //value={() => }
                  label="Status"
                  onChange={handleChange}
                >
                  {statusOptions.map((status) => {
                    return (
                      <MenuItem
                        name={status}
                        value={status}
                        //selected={getSelected(status)}
                      >
                        {status}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Likes"
                name="like_count"
                value={formData.like_count}
                margin="normal"
                readonly
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <TextField
                fullWidth
                label="Date posted"
                name="date_posted"
                value={formData.date_posted}
                margin="normal"
                readonly
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <Box mt={3} justifyContent={"center"} align={"center"}>
                <Button
                  mt={3}
                  //fullWidth

                  type="submit"
                  variant="contained"
                  sx={{
                    "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                    //bgcolor: "#9dc6d8",
                    bgcolor: "#5ba7d5",
                  }}
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
              </Box>
            </form>
          </Box>
          <Box direction="column">
            <Typography
              flex={6}
              variant="h6"
              justifyContent={"center"}
              align="center"
              mb={2}
            >
              View and update related images
            </Typography>
            <DataGrid
              //fullwidth
              rows={rows}
              getRowId={(row) => row.id}
              getRowHeight={() => "auto"}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 15 },
                },
              }}
              options={
                {
                  //tableLayout: "fixed",
                  //responsive: "scroll"
                }
              }
              pageSizeOptions={[5, 10, 15, 20, 30, 50]}
              checkboxSelection
            />
          </Box>
          <Box align={"center"}>
            <div>
              <Button
                variant={"contained"}
                onClick={() => document.getElementById("addimage").click()}
              >
                Add new image
              </Button>
              <input
                type="file"
                name="addimage"
                id="addimage"
                //value={formData.image}
                accept="image/*"
                onChange={handleAddImage}
                style={{ display: "none" }}
                required
              ></input>
            </div>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default HumorView;
