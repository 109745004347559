import React from "react";
import { Box, Grid, Typography, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

import logo_lg from "../../static/logo_image_new_1600_500_jpeg.jpg";
import logo_md from "../../static/logo_image_new_1000_500_jpeg.jpg";
import logo_xs from "../../static/logo_image_new_1000_1000_jpeg.jpg";

const Topimage = () => {
  const theme = createTheme();
  /*The theme.typography.* variant properties map directly to the 
generated CSS. You can use media queries inside them.
*/
  theme.typography.h5 = {
    fontSize: "1.5rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Box
          height="50vh"
          display="flex"
          justifyContent="center"
          sx={{
            backgroundImage: {
              xs: `url(${logo_xs})`,
              md: `url(${logo_md})`,
              lg: `url(${logo_lg})`,
            },
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <Box>
            <ThemeProvider theme={theme}>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  position: "absolute",
                  color: "#5ba7d5",
                  top: "50%",
                  transform: {
                    xs: "translate(-10%, -50%)",
                    md: "translate(-10%, -50%)",
                    lg: "translate(-50%, -50%)",
                  },
                  fontFamily: "Single Day, cursive",
                }}
              >
                From thought to feel, <br />
                from feel to invention. <br />
                Aren't we incredible!
              </Typography>
            </ThemeProvider>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Topimage;
