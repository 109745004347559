import React from "react";
import axios from "axios";
import { Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import Postscard from "../posts_home/Postscard";
import PaginatedPosts from "../pagination/PaginatedPosts";

const PostsHumor = () => {
  const [humorPosts, setHumorPosts] = useState([]);
  const [muiPaginateCount, setMuiPaginateCount] = useState(0);
  const pageUrlBase = "humorapi/humorposts";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/humorapi/humorposts/`
        );
        setHumorPosts(resp.data.results);
        //console.log(resp.data.results);
        let drfPagesizeForPagination = resp.data.results.length;
        //console.log("drfPagesizeForPagination" + drfPagesizeForPagination);
        const paginateCnt = Math.ceil(
          resp.data.count / drfPagesizeForPagination
        );
        setMuiPaginateCount(paginateCnt);
        //console.log(resp.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const getPagePosts = (childData) => {
    setHumorPosts(childData);
  };

  return (
    <Container justifyContent="center" align="center">
      <Grid
        container
        width="100%"
        direction={"column"}
        spacing={1}
        justifySelf={"center"}
      >
        {humorPosts.map((post, key) => {
          return (
            <Grid item xs>
              <Postscard
                key={key}
                content={{ value: ["Humor"] }}
                postHref={`/humor/details/${post.slug}`}
                title={post.title}
                excerpt={post.excerpt}
                date_posted={post.date_posted}
                image={post.images[0].image}
              />
            </Grid>
          );
        })}
      </Grid>

      {muiPaginateCount > 1 ? (
        <PaginatedPosts
          muiPaginateCount={muiPaginateCount}
          pageUrlBase={pageUrlBase}
          sendDataToParent={getPagePosts}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default PostsHumor;
