const JWT_REGEX = /^[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/;
const FULLNAME_REGEX = /^[a-zA-Z\s]+$/;
const EMAIL_REGEX = /^(?=[a-zA-Z]+[\w\.]*@[a-zA-Z]+[a-zA-Z-]+\.[a-zA-Z.]+$)/;
const PWD_REGEX =
  /^(?=[\w!@#$%]*[a-z]+)(?=[\w!@#$%]*[A-Z]+)(?=[\w!@#$%]*[0-9]+)(?=[\w!@#$%]*[!@#$%]+)([\w!@#$%]{8,15})$/;
const ROLE_REGEX = /^[0-9]{3}$/;

const menu_home = {
  menu: { Name: "Home", url: "/home" },
};

const menu_contact = {
  menu: { Name: "Contact", url: "/contact" },
};

const menu_about = {
  menu: { Name: "About", url: "/about" },
};

const menu_science = [
  {
    Name: "Science",
    url: "",
    submenu: [
      { Name: "Biology&Us", url: "/science/biologyandus" },
      { Name: "AI&Us", url: "/science/aiandus" },
    ],
  },
];
const menu_fiction = {
  menu: { Name: "Fiction", url: "/fiction" },
};

const menu_humor = {
  menu: { Name: "Humou(?)r", url: "/humor" },
};

const menu_null = {
  menu: { Name: "", url: "/" },
};

const menu_md_home = [
  menu_home.menu,
  menu_contact.menu,
  menu_about.menu,
  menu_null.menu,
];
const menu_md_others = [menu_fiction.menu, menu_humor.menu, menu_null.menu];

const menu_xs_home = [menu_home.menu];
const menu_xs_others = [
  menu_fiction.menu,
  menu_humor.menu,
  menu_contact.menu,
  menu_about.menu,
];

const menu_admin = [
  {
    Name: "Admin",
    url: "/controller",
  },
];

//console.log(menu_md_home);

const menu_account = [
  {
    Name: "My account",
    url: "",
    submenu: [
      { Name: "Change password", url: "/myaccount/changepassword" },
      { Name: "Logout", url: "/myaccount/logout" },
    ],
  },
];

//home paths
const home_paths = ["/", "/home"];
const post_pages = [
  "science/biologyandus",
  "science/aiandus",
  "humor",
  "fiction",
];
const controller_pages = ["users", "biology", "ai", "humor", "fiction"];
const statusOptions = ["Pending", "Published", "Inactive"];
//const roleOptions = ["Accountuser", "Admin", "Editor", "Inactive"];
const roleOptions = [
  { value: "201", name: "Accountuser" },
  { value: "101", name: "Admin" },
  { value: "301", name: "Editor" },
  { value: "401", name: "Inactive" },
];

export {
  menu_science,
  menu_md_home,
  menu_md_others,
  menu_xs_home,
  menu_xs_others,
  menu_admin,
  menu_account,
  home_paths,
  post_pages,
  controller_pages,
  statusOptions,
  roleOptions,
  JWT_REGEX,
  FULLNAME_REGEX,
  EMAIL_REGEX,
  PWD_REGEX,
  ROLE_REGEX,
};
