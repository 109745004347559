import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  MenuItem,
  InputLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { statusOptions } from "../../../utils/UsedProps";

const FictionCreate = () => {
  const [errorData, setErrorData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const createNew = async (e) => {
    const form = document.querySelector("form");
    //alert("Hii");
    e.preventDefault();
    let formData = new FormData(form);
    //console.log("Formdata=", formData);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/controllerapi/fiction/create/`,
        {
          title: formData.get("title"),
          summary: formData.get("summary"),
          content1: formData.get("content1"),
          content2: formData.get("content2"),
          footnotes: formData.get("footnotes"),
          status: formData.get("status"),
          image: formData.get("image"),
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      let successMsg =
        "New post (" + formData.get("title") + ") successfully created!";
      navigate("/controller/fiction", { state: { successMsg: successMsg } });
    } catch (error) {
      setErrorMsg("Failed to create post! Please correct the errors.");
      //console.log("Response=\n", error.response.data);
      if (error.response.data !== null) {
        setErrorData(error.response.data);
      }
    }
  };

  const handleImage = (e) => {
    e.preventDefault();
    const image = e.target.files[0];
    e.target.image = image;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      createNew(e);
    } catch (error) {
      //alert("Failed to create a new post!");
      setErrorMsg("Failed to create post! Please correct the errors.");
      console.log(error);
    }
  };

  return (
    <>
      <div className={errorMsg ? "errmsg" : "hide"}>
        <span>{errorMsg}</span>
      </div>
      <Stack display="flex" flexDirection={"row"}>
        <Box display={"flex"} flex={6} m={2} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              flex={6}
              variant="h6"
              justifyContent={"center"}
              align="center"
            >
              Create new post
            </Typography>
          </Box>

          <Box
            //display={"block"}
            display={"flex"}
            spacing={1}
            m={2}
            justifyContent={"center"}
            fullWidth
          >
            <form id="createForm" onSubmit={handleSubmit} width="inherit">
              <div className={errorData.title ? "errdetail" : "hide"}>
                <span>{errorData.title}</span>
              </div>
              <TextField
                fullWidth
                label="Title"
                name="title"
                margin="normal"
                required
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <div className={errorData.summary ? "errdetail" : "hide"}>
                <span>{errorData.summary}</span>
              </div>
              <TextField
                fullWidth
                label="Summary"
                name="summary"
                margin="normal"
                required
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <div className={errorData.content1 ? "errdetail" : "hide"}>
                <span>{errorData.content1}</span>
              </div>
              <TextField
                fullWidth
                label="Content1"
                name="content1"
                margin="normal"
                required
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <div className={errorData.image ? "errdetail" : "hide"}>
                <span>{errorData.image}</span>
              </div>
              <label htmlFor="image">Image</label>
              <input
                type="file"
                name="image"
                id="image"
                accept="image/*"
                onChange={handleImage}
                required
              ></input>
              <TextField
                fullWidth
                label="Content2"
                name="content2"
                margin="normal"
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <TextField
                fullWidth
                label="Footnotes"
                name="footnotes"
                margin="normal"
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <FormControl fullWidth>
                <InputLabel id="status" InputLabelProps={{ shrink: true }}>
                  Status
                </InputLabel>
                <Select
                  //defaultValue={initialValues.status}
                  labelId="status"
                  id="status"
                  name="status"
                  label="Status"
                >
                  {statusOptions.map((status) => {
                    return (
                      <MenuItem
                        name={status}
                        value={status}
                        //selected={getSelected(status)}
                      >
                        {status}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box mt={3} justifyContent={"center"} align={"center"}>
                <Button
                  mt={3}
                  //fullWidth

                  type="submit"
                  variant="contained"
                  sx={{
                    "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                    bgcolor: "#5ba7d5",
                  }}
                  onClick={handleSubmit}
                >
                  Save new
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default FictionCreate;
