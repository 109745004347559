import axios from "axios";
import React from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { PWD_REGEX } from "../../utils/UsedProps";
import useAuth from "../../context/useAuth";
import { useNavigate } from "react-router-dom";

const ChangePwd = () => {
  const { email, setAuthToken, setEmail, setRole } = useAuth();
  const navigate = useNavigate();

  const initializeData = Object.freeze({
    oldPassword: "",
    newPassword: "",
  });

  const [formData, setFormData] = useState(initializeData);

  const [validOldPwd, setValidOldPwd] = useState(false);
  const [oldPwdFocus, setOldPwdFocus] = useState(false);

  const [validPwd, setValidPwd] = useState(false);
  const [newPwdFocus, setNewPwdFocus] = useState(false);

  //confirm password field
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  useEffect(() => {
    let oldPwd = formData.oldPassword;
    const result = PWD_REGEX.test(oldPwd);
    //console.log(result);
    //console.log(pwd);
    setValidOldPwd(result);

    //console.log("Match=" + validMatch);
  }, [formData.oldPassword]);

  /* applied to the password field and confirm pwd field. 
    The advantage of having both in the same useEffect hook is that 
    anytime one of them changes, it checks both fields and allows the 
    fields to be in sync with each other.
    */
  useEffect(() => {
    let newPwd = formData.newPassword;
    const result = PWD_REGEX.test(newPwd);
    //console.log(result);
    //console.log(pwd);
    setValidPwd(result);
    /*set variable match to pwd value and test it against confirm pwd
    field to return a boolean in the end.
    */
    const match = newPwd === matchPwd;
    setValidMatch(match);
    //console.log("Match=" + validMatch);
  }, [formData.newPassword, matchPwd]);

  const [errorData, setErrorData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleChange = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const createNew = async (e) => {
    const form = document.querySelector("form");
    e.preventDefault();
    let formData = new FormData(form);
    let data = {};
    data["email"] = email;
    data["old_password"] = formData.get("oldPassword");
    data["new_password"] = formData.get("newPassword");

    //console.log("Data=", data);

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/userapi/change_password`,
        data,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      setSuccessMsg(
        "Your password has been changed. Please login using your new password."
      );

      localStorage.removeItem("authToken");
      setAuthToken(null);
      setEmail(null);
      setRole(null);

      let succMsg =
        "Your password has been changed. Please login using your new password.";
      //console.log("Success msg" + `${successMsg}`);
      setErrorMsg("");
      navigate("/signin", {
        state: { successMsg: succMsg },
      });
    } catch (error) {
      let err = "Failed to change password!";
      if (error.response.data !== null) {
        if (/^.*Incorrect password.*$/.test(error.response.data["detail"])) {
          err = "Failed to reset password. Incorrect password!";
        } else if (/^.*no account.*$/.test(error.response.data["detail"])) {
          err =
            "Failed to reset password. User account does not exist for this email id!";
        }

        setErrorMsg(err);
        setSuccessMsg("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      createNew(e);
    } catch (error) {
      setErrorMsg("Password could not be changed.");
      //console.log(error);
    }
  };

  return (
    <>
      <div style={{ marginTop: 4 }} className={errorMsg ? "errmsg" : "hide"}>
        <span>{errorMsg}</span>
      </div>

      <div className={successMsg ? "successmsg" : "hide"}>
        <span>{successMsg}</span>
      </div>
      <Box
        m={3}
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box display={"flex"} flex={6} flexDirection={"column"} width="50vw">
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              flex={6}
              variant="h6"
              justifyContent={"center"}
              align="center"
            >
              Change password
            </Typography>
          </Box>

          <Box display={"block"} spacing={1} m={2} fullWidth>
            <form id="createForm" onSubmit={handleSubmit} width="inherit">
              <Box width="inherit">
                <div className={errorData.oldPassword ? "errdetail" : "hide"}>
                  <span>{errorData.oldPassword}</span>
                </div>
                <TextField
                  fullWidth
                  type="password"
                  label="Old Password"
                  name="oldPassword"
                  margin="normal"
                  required
                  onChange={handleChange}
                  onFocus={() => setOldPwdFocus(true)}
                  onBlur={() => setOldPwdFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </Box>

              <Box width="inherit">
                <div className={errorData.password ? "errdetail" : "hide"}>
                  <span>{errorData.password}</span>
                </div>
                <span className={validPwd ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span
                  className={
                    validPwd || !formData.newPassword ? "hide" : "invalid"
                  }
                >
                  <CloseIcon />
                </span>
                <TextField
                  fullWidth
                  type="password"
                  label="New Password"
                  name="newPassword"
                  margin="normal"
                  required
                  onChange={handleChange}
                  onFocus={() => setNewPwdFocus(true)}
                  onBlur={() => setNewPwdFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="pwdnote"
                  className={
                    newPwdFocus && formData.newPassword && !validPwd
                      ? "instructions"
                      : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    Must be between 8 to 20 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character. <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>
                    <span aria-label="at symbol">@</span>
                    <span aria-label="hashtag">#</span>
                    <span aria-label="dollar sign">$</span>
                    <span aria-label="percent">%</span>
                    <br />
                  </p>
                </span>
              </Box>

              <Box width="inherit">
                <div className={errorData.password ? "errdetail" : "hide"}>
                  <span>{errorData.password}</span>
                </div>
                <span className={validPwd && validMatch ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                  <CloseIcon />
                </span>
                <TextField
                  type="password"
                  fullWidth
                  label="Confirm password"
                  name="cnfpassword"
                  margin="normal"
                  required
                  onChange={(e) => setMatchPwd(e.target.value)}
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="confirmnote"
                  className={
                    matchFocus && !validMatch ? "instructions" : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    Must match the Password input field.
                    <br />
                  </p>
                </span>
              </Box>

              <Box mt={3} justifyContent={"center"} align={"center"}>
                <Button
                  disabled={
                    !validOldPwd || !validPwd || !validMatch ? true : false
                  }
                  mt={3}
                  type="submit"
                  variant="contained"
                  sx={{
                    "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                    bgcolor: "#5ba7d5",
                    textTransform: "None",
                  }}
                  onClick={handleSubmit}
                >
                  Change password
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChangePwd;
