import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { FULLNAME_REGEX, EMAIL_REGEX, PWD_REGEX } from "../../utils/UsedProps";

const Signup = () => {
  //const fullnameRef = useRef();

  const initializeData = Object.freeze({
    fullname: "",
    email: "",
    password: "",
    role: "",
  });

  const [formData, setFormData] = useState(initializeData);

  //const [fullName, setFullName] = useState("");
  const [validFullName, setValidFullName] = useState(false);
  const [fullNameFocus, setFullNameFocus] = useState(false);

  //const [user, setUser] = useState("");
  const [validUser, setValidUser] = useState(false);
  //whether we set focus on the user field or not
  const [userFocus, setUserFocus] = useState(false);

  //const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  //confirm password field
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  /*hook for setting the focus when the component loads. 
  So, we pass nothing in the dependency array [] to make
  it only happen when the component loads.
  */
  /*
  useEffect(() => {
    //set focus on username field when component loads.
    fullnameRef.current.focus();
    //setFullNameFocus(true);
  }, []);
  */

  useEffect(() => {
    let fullName = formData.fullname;
    if (fullName === "") {
      setValidFullName(true);
    } else {
      const result = FULLNAME_REGEX.test(fullName);
      //console.log("Fullname res=", result);
      //console.log("FullName=", fullName);
      setValidFullName(result);
    }
  }, [formData.fullname]);

  /* applied to the username field. Since we pass user 
  value to dependency array, it checks it everytime the value changes.
*/
  useEffect(() => {
    let user = formData.email;
    const result = EMAIL_REGEX.test(user);
    //console.log(result);
    //console.log(user);
    setValidUser(result);
  }, [formData.email]);

  /* applied to the password field and confirm pwd field. 
The advantage of having both in the same useEffect hook is that 
anytime one of them changes, it checks both fields and allows the 
fields to be in sync with each other.
*/
  useEffect(() => {
    let pwd = formData.password;
    const result = PWD_REGEX.test(pwd);
    //console.log(result);
    //console.log(pwd);
    setValidPwd(result);
    /*set variable match to pwd value and test it against confirm pwd
    field to return a boolean in the end.
    */
    const match = pwd === matchPwd;
    setValidMatch(match);
    //console.log("Match=" + validMatch);
  }, [formData.password, matchPwd]);

  const [errorData, setErrorData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const createNew = async (e) => {
    const form = document.querySelector("form");
    //alert("Hii");
    e.preventDefault();
    let formData = new FormData(form);

    //console.log("Formdata=", formData);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/controllerapi/users/create`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      //console.log(formData.email);
      let successMsg =
        "New user (" + formData.get("email") + ") successfully added!";
      navigate(-1, { state: { successMsg: successMsg } });
    } catch (error) {
      setErrorMsg("Failed to add user! Please correct the errors.");
      //console.log("Response=\n", error.response.data);
      if (error.response.data !== null) {
        setErrorData(error.response.data);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      createNew(e);
    } catch (error) {
      //alert("Failed to add user!");
      setErrorMsg("Failed to add user! Please correct the errors.");
      console.log(error);
    }
  };

  return (
    <>
      <div style={{ marginTop: 4 }} className={errorMsg ? "errmsg" : "hide"}>
        <span>{errorMsg}</span>
      </div>
      <Box
        m={3}
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box
          display={"flex"}
          flex={6}
          flexDirection={"column"}
          width={{ xs: "80vw", md: "50vw" }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              flex={6}
              variant="h6"
              justifyContent={"center"}
              align="center"
            >
              Signup
            </Typography>
          </Box>

          <Box
            //display={"flex"}
            display={"block"}
            spacing={1}
            m={2}
            //justifyContent={"center"}
            // align={"center"}
            fullWidth
            //width="50vw"
          >
            <form id="createForm" onSubmit={handleSubmit} width="inherit">
              <Box width="inherit">
                <div className={errorData.fullname ? "errdetail" : "hide"}>
                  <span>{errorData.fullname}</span>
                </div>

                <span
                  className={
                    validFullName && formData.fullname !== "" ? "valid" : "hide"
                  }
                >
                  <DoneAllIcon />
                </span>

                <span
                  className={
                    validFullName || !formData.fullname ? "hide" : "invalid"
                  }
                >
                  <CloseIcon />
                </span>

                <TextField
                  //ref={fullnameRef}
                  fullWidth
                  label="Fullname"
                  name="fullname"
                  margin="normal"
                  onChange={handleChange}
                  onFocus={() => setFullNameFocus(true)}
                  onBlur={() => setFullNameFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="fullNamenote"
                  className={
                    fullNameFocus && formData.fullname !== "" && !validFullName
                      ? "instructions"
                      : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    <br />
                    Only letters and spaces allowed.
                  </p>
                </span>
              </Box>

              <Box width="inherit">
                <div className={errorData.email ? "errdetail" : "hide"}>
                  <span>{errorData.email}</span>
                </div>
                <span className={validUser ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span
                  className={validUser || !formData.email ? "hide" : "invalid"}
                >
                  <CloseIcon />
                </span>

                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  margin="normal"
                  required
                  onChange={handleChange}
                  onFocus={() => setUserFocus(true)}
                  onBlur={() => setUserFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="uidnote"
                  className={
                    userFocus && formData.email && !validUser
                      ? "instructions"
                      : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    <br />
                    Must be between 4 to 20 characters.
                    <br />
                    Must begin with a letter.
                    <br />
                    Only letters, numbers, underscores, hyphens, dots allowed.
                  </p>
                </span>
              </Box>
              <Box width="inherit">
                <div className={errorData.password ? "errdetail" : "hide"}>
                  <span>{errorData.password}</span>
                </div>
                <span className={validPwd ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span
                  className={
                    validPwd || !formData.password ? "hide" : "invalid"
                  }
                >
                  <CloseIcon />
                </span>
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  name="password"
                  margin="normal"
                  required
                  onChange={handleChange}
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="pwdnote"
                  className={
                    pwdFocus && formData.password && !validPwd
                      ? "instructions"
                      : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    Must be between 8 to 20 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character. <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>
                    <span aria-label="at symbol">@</span>
                    <span aria-label="hashtag">#</span>
                    <span aria-label="dollar sign">$</span>
                    <span aria-label="percent">%</span>
                    <br />
                  </p>
                </span>
              </Box>

              <Box width="inherit">
                <div className={errorData.password ? "errdetail" : "hide"}>
                  <span>{errorData.password}</span>
                </div>
                <span className={validPwd && validMatch ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                  <CloseIcon />
                </span>
                <TextField
                  type="password"
                  fullWidth
                  label="Confirm password"
                  name="cnfpassword"
                  margin="normal"
                  required
                  onChange={(e) => setMatchPwd(e.target.value)}
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="confirmnote"
                  className={
                    matchFocus && !validMatch ? "instructions" : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    Must match the Password input field.
                    <br />
                  </p>
                </span>
              </Box>

              <Box mt={3} justifyContent={"center"} align={"center"}>
                <Button
                  disabled={
                    !validFullName || !validUser || !validPwd || !validMatch
                      ? true
                      : false
                  }
                  mt={3}
                  //fullWidth

                  type="submit"
                  variant="contained"
                  sx={{
                    "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                    //bgcolor: "#9dc6d8",
                    bgcolor: "#5ba7d5",
                  }}
                  onClick={handleSubmit}
                >
                  Signup
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Signup;
