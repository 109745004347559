import axios from "axios";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, TextField, Typography, Button } from "@mui/material";

import { useState, useEffect } from "react";
import { useRef } from "react";
import useAuth from "../../context/useAuth";
import { jwtDecode } from "jwt-decode";
import {
  JWT_REGEX,
  EMAIL_REGEX,
  PWD_REGEX,
  ROLE_REGEX,
} from "../../utils/UsedProps";

const Login = () => {
  const { setAuthToken, setEmail, setRole } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const errMsg = location.state?.errMsg;
  let succMsg = location.state?.successMsg;

  //console.log("Location:" + JSON.stringify(location.state));

  const initializeData = Object.freeze({
    email: "",
    password: "",
  });

  const userRef = useRef();
  const [formData, setFormData] = useState(initializeData);

  //const [user, setUser] = useState("");
  const [validUser, setValidUser] = useState(false);
  //whether we set focus on the user field or not
  const [userFocus, setUserFocus] = useState(false);

  //const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [errorMsg, setErrorMsg] = useState(errMsg) || "";
  const [successMsg, setSuccessMsg] = useState(succMsg) || "";

  /*hook for setting the focus when the component loads. 
  So, we pass nothing in the dependency array [] to make
  it only happen when the component loads.
  */
  useEffect(() => {
    //set focus on username field when component loads.
    userRef.current.focus();
    //setFullNameFocus(true);
  }, []);

  /* applied to the username field. Since we pass user 
  value to dependency array, it checks it everytime the value changes.
*/
  useEffect(() => {
    let user = formData.email;
    const result = EMAIL_REGEX.test(user);
    //console.log(result);
    //console.log(user);
    setValidUser(result);
  }, [formData.email]);

  /* applied to the password field and confirm pwd field. 
The advantage of having both in the same useEffect hook is that 
anytime one of them changes, it checks both fields and allows the 
fields to be in sync with each other.
*/
  useEffect(() => {
    let pwd = formData.password;
    const result = PWD_REGEX.test(pwd);
    //console.log(result);
    //console.log(pwd);
    setValidPwd(result);

    //console.log("Match=" + validMatch);
  }, [formData.password]);

  const handleChange = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    const form = document.querySelector("form");
    //alert("Hii");
    e.preventDefault();
    let formData = new FormData(form);

    if (!validUser || !validPwd) {
      setErrorMsg("Invalid credentials.");
      setSuccessMsg("");
      return;
    }

    //console.log("Formdata=", formData.get("email"));

    /* console.log(
      JSON.stringify({ email: formData.email, password: formData.password })
    );
    */

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapi/login`,
        //formData,
        JSON.stringify({
          email: formData.get("email"),
          password: formData.get("password"),
        }),
        {
          headers: {
            //"Content-type": "multipart/form-data",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const accessToken = res?.data?.alloted;
      //console.log(accessToken);
      if (JWT_REGEX.test(accessToken)) {
        localStorage.setItem("authToken", JSON.stringify(accessToken));
        setAuthToken(accessToken);
        let email = jwtDecode(accessToken).email;
        EMAIL_REGEX.test(email) ? setEmail(email) : setEmail(null);
        let role = jwtDecode(accessToken).role;
        ROLE_REGEX.test(role) ? setRole(role) : setRole(null);

        setFormData(initializeData);
        /*successMsg =
          "New user (" + formData.get("email") + ") successfully logged in!";
          */
        //navigate("/refresh");
        setErrorMsg("");
        setSuccessMsg(succMsg);
        navigate(-1, {
          state: { successMsg: succMsg },
          //state: { successMsg: successMsg, email: email, role: role },
        });
      } else {
        setErrorMsg("Unauthorized.");
        setSuccessMsg("");
      }
    } catch (err) {
      //console.log(err);
      if (!err.response) {
        setErrorMsg("No server response");
      } else if (err.response?.status === 400) {
        setErrorMsg("Missing username or password");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized.");
      } else if (err.response?.status === 403) {
        setErrorMsg("Invalid credentials.");
      } else {
        setErrorMsg("Login failed.");
      }
      setSuccessMsg("");
    }
  };

  return (
    <>
      <div style={{ marginTop: 4 }} className={errorMsg ? "errmsg" : "hide"}>
        <span>{errorMsg}</span>
      </div>
      <div
        style={{ marginTop: 4 }}
        className={successMsg ? "successmsg" : "hide"}
      >
        <span>{successMsg}</span>
      </div>
      <Box
        m={3}
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box
          display={"flex"}
          flex={6}
          flexDirection={"column"}
          width={{ xs: "80vw", md: "50vw" }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              flex={6}
              variant="h4"
              align="center"
              m={3}
              sx={{ fontFamily: "Single Day, cursive" }}
              justifyContent={"center"}
              color="#5ba7d5"
            >
              Signin
            </Typography>
          </Box>

          <Box
            //display={"flex"}
            display={"block"}
            spacing={1}
            m={2}
            //justifyContent={"center"}
            // align={"center"}
            fullWidth
            //width="50vw"
          >
            <form id="createForm" onSubmit={handleSubmit} width="inherit">
              <Box width="inherit">
                <Box width="inherit">
                  <TextField
                    ref={userRef}
                    fullWidth
                    label="Email"
                    name="email"
                    margin="normal"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </Box>
                <Box width="inherit">
                  <TextField
                    fullWidth
                    type="password"
                    label="Password"
                    name="password"
                    margin="normal"
                    required
                    onChange={handleChange}
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </Box>

                <Box mt={3} justifyContent={"center"} align={"center"}>
                  <Button
                    mt={3}
                    //fullWidth

                    type="submit"
                    variant="contained"
                    sx={{
                      "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                      //bgcolor: "#9dc6d8",
                      bgcolor: "#5ba7d5",
                    }}
                    onClick={handleSubmit}
                  >
                    Signin
                  </Button>
                </Box>
              </Box>
            </form>
            <Typography m={2} align={"center"}>
              Don't have an account yet? <a href="/signup">Signup</a> for free.
            </Typography>

            <Typography m={2} align={"center"}>
              <a href="/reset_password">Forgot password</a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
