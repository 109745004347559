import Footer from "./components/footer/Footer";
import Main from "./components/main/Main";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Topimage from "./components/topimage/Topimage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainDetails from "./components/details/MainDetails";
import Contact from "./components/contact/Contact";
import Controller from "./components/controller/Controller";
import Notfound from "./components/notfound/Notfound";
import { home_paths, post_pages, controller_pages } from "./utils/UsedProps";
import MainView from "./components/controller/MainView";
import MainUpdate from "./components/controller/MainUpdate";
import MainCreate from "./components/controller/MainCreate";
import MainHistory from "./components/controller/MainHistory";
//import Privateroute from "./utils/Privateroute";
import RequireAuth from "./utils/RequireAuth";
import Login from "./components/signin/Login";
import Logout from "./components/logout/Logout";
import Signup from "./components/signup/Signup";
import ResetPwd from "./components/resetpwd/ResetPwd";
import ResetPwdLinkSend from "./components/resetpwd/ResetPwdLinkSend";
import ChangePwd from "./components/changepwd/ChangePwd";
import Unauthorized from "./components/unauthorized/Unauthorized";
import axios from "axios";

function App() {
  const secretKey = process.env.REACT_APP_DIY_SECRET;
  //console.log("JWT key=", secretKey);

  //add 'Authorization' header to ALL axios requests.
  //This header allows my custom DRF middleware to prevent direct API access.
  axios.defaults.headers.common["Authorization"] = `${secretKey}`;

  return (
    <>
      <BrowserRouter>
        <Topimage />
        <Navbar />

        <Routes>
          {home_paths.map((item) => {
            return <Route path={item} element={<Main myorigin={"home"} />} />;
          })}

          {post_pages.map((item) => {
            return (
              <>
                <Route path={`/${item}`} element={<Main myorigin={item} />} />
                <Route
                  path={`/${item}/details/:slug`}
                  element={<MainDetails myorigin={item} />}
                />
              </>
            );
          })}

          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />

          <Route element={<RequireAuth allowedRoles={[101]} />}>
            <Route
              path="/controller"
              element={<Controller origin="biology" />}
            />
            {controller_pages.map((item) => {
              return (
                <>
                  <Route
                    path={`/controller/${item}`}
                    element={<Controller origin={item} />}
                  />
                  <Route
                    path={`/controller/${item}/create`}
                    element={<MainCreate origin={item} />}
                  />
                  <Route
                    path={`/controller/${item}/view/:id`}
                    element={<MainView origin={item} />}
                  />
                  <Route
                    path={`/controller/${item}/view/:id/history`}
                    element={<MainHistory origin={item} />}
                  />
                  <Route
                    path={`/controller/${item}/update/:id`}
                    element={<MainUpdate origin={item} />}
                  />
                </>
              );
            })}
          </Route>

          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset_password" element={<ResetPwdLinkSend />} />
          <Route path="/reset_password/:token" element={<ResetPwd />} />
          <Route path="/myaccount/logout" element={<Logout />} />
          <Route path="/myaccount/changepassword" element={<ChangePwd />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          <Route path="/subscribe" />
          <Route path="/refresh" />

          <Route path="/*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;

/*
<Route element={<Privateroute allowedRoles={[101]} />}>

<Route path="/" element={<Main myorigin={"home"} />} />
          <Route path="/home" element={<Main myorigin={"home"} />} />

          <Route
            path="/controller/biology"
            element={<Controller origin={"biology"} />}
          />
          <Route path="/controller/ai" element={<Controller origin={"ai"} />} />
          <Route
            path="/controller/humor"
            element={<Controller origin={"humor"} />}
          />
          <Route
            path="/controller/fiction"
            element={<Controller origin={"fiction"} />}
          />

           <Route
            path="/science/biologyandus"
            element={<Main myorigin={"biologyandus"} />}
          />
          <Route
            path="/science/biologyandus/details/:slug"
            element={<MainDetails myorigin={"biologyandus"} />}
          />
          <Route
            path="/science/aiandus"
            element={<Main myorigin={"aiandus"} />}
          />
          <Route
            path="/science/aiandus/details"
            element={<MainDetails myorigin={"aiandus"} />}
          />
          <Route path="/fiction" element={<Main myorigin={"fiction"} />} />
          <Route
            path="/fiction/details"
            element={<MainDetails myorigin={"fiction"} />}
          />
          <Route path="/humor" element={<Main myorigin={"humor"} />} />
          <Route
            path="/humor/details/:slug"
            element={<MainDetails myorigin={"humor"} />}
          />
*/
