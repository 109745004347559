import React from "react";
import { useState } from "react";
import axios from "axios";
import { Box, Pagination } from "@mui/material";

const PaginatedPosts = ({
  muiPaginateCount,
  pageUrlBase,
  sendDataToParent,
}) => {
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = async (e, newPage) => {
    setActivePage(newPage);
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}/${pageUrlBase}/?page=${newPage}`
      );
      //console.log("Child=" + muiPaginateCount);
      sendDataToParent(resp.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        mt={2}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Pagination
          count={muiPaginateCount}
          page={activePage}
          //color="#5ba7d5"
          //color="primary"
          sx={{
            "Button.MuiPaginationItem-circular.Mui-selected": {
              backgroundColor: "#5ba7d5",
              color: "white",
            },
          }}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
          hideNextButton
          hidePrevButton
        />
      </Box>
    </>
  );
};

export default PaginatedPosts;
