import { useNavigate } from "react-router-dom";
import useAuth from "../../context/useAuth";

const Logout = () => {
  const { setAuthToken, setEmail, setRole } = useAuth();
  const navigate = useNavigate();
  localStorage.removeItem("authToken");
  setAuthToken(null);
  setEmail(null);
  setRole(null);

  return navigate(-1);
};

export default Logout;

/*
<Navigate to="/signin" state={{ from: location }} replace />;
*/
