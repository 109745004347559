import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const BiologyMain = ({ currUrl }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const location = useLocation();
  let successMsg;
  if (location.state !== null) {
    successMsg = location.state.successMsg;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/controllerapi/users/summary`
        );
        setRows(resp.data);
        getCols(resp.data);
        //console.log(resp.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getCols = (rows) => {
      let cols = [];

      let rowkeys = Object.keys(rows[0]);
      //console.log(rowkeys);
      for (let i = 0; i < rowkeys.length; i++) {
        let keyname = rowkeys[i];
        cols[i] = {
          field: keyname,
          headerName: keyname.toUpperCase(),
          //width: 150,
          flex: 0.4,
          minWidth: 50,
        };
      }
      let viewbutton = {
        field: "view",
        headerName: "View",
        sortable: false,
        renderCell: ({ row }) => (
          <Button
            variant="contained"
            sx={{
              "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
              //bgcolor: "#9dc6d8",
              bgcolor: "#5ba7d5",
            }}
            href={`${currUrl}/view/${row.id}`}
          >
            View
          </Button>
        ),
      };

      cols.push(viewbutton);
      setColumns(cols);
    };
    fetchData();
  }, []);

  /*
    console.log("Rows=", rows);
    console.log("Columns=", columns);
    */

  return (
    <>
      <div className={successMsg ? "successmsg" : "hide"}>
        <span>{successMsg}</span>
      </div>

      <Box display="flex" width={"80vw"}>
        <Stack direction={"column"} width={"100%"}>
          <Box justifyContent={"center"} align={"center"} m={2}>
            <Button
              mt={3}
              //fullWidth

              type="submit"
              variant="contained"
              sx={{
                "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                bgcolor: "#5ba7d5",
              }}
              href={`${currUrl}/create/`}
            >
              Add New User
            </Button>
          </Box>
          <DataGrid
            //fullwidth
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            options={
              {
                //tableLayout: "fixed",
                //responsive: "scroll"
              }
            }
            pageSizeOptions={[5, 10, 15, 20, 30, 50]}
            checkboxSelection
          />
        </Stack>
      </Box>
    </>
  );
};

export default BiologyMain;
