import React from "react";
import { Stack, Box } from "@mui/material";
import Sidemenu from "./Sidemenu";
import BiologyView from "./biology/BiologyView";
import AiView from "./ai/AiView";
import FictionView from "./fiction/FictionView";
import HumorView from "./humor/HumorView";
import UsersView from "./users/UsersView";

const MainView = ({ origin }) => {
  return (
    <Stack display="flex" flexDirection={"row"}>
      <Box flex={1}>
        <Sidemenu />
      </Box>
      <Box flex={6} m={2}>
        {origin === "users" ? (
          <UsersView origin={origin} />
        ) : origin === "biology" ? (
          <BiologyView origin={origin} />
        ) : origin === "ai" ? (
          <AiView origin={origin} />
        ) : origin === "fiction" ? (
          <FictionView origin={origin} />
        ) : origin === "humor" ? (
          <HumorView origin={origin} />
        ) : (
          ""
        )}
      </Box>
    </Stack>
  );
};

export default MainView;
