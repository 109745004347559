import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import lazyimage from "../../static/lazy_button_jpeg.jpg";
const LazyButton = () => {
  const [showLazyButton, setShowLazyButton] = useState(true);

  const handleClick = async () => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    setShowLazyButton(false);
    await delay(2000);
    setShowLazyButton(true);
  };

  return (
    <Box flex={1} flexDirection={"row"} sx={{ padding: 3 }}>
      {showLazyButton && (
        <Box id="lazybutton" align={"center"} m={2}>
          <Button
            //href={"#"}
            variant={"contained"}
            //align={"center"}
            justifyContent={"center"}
            onClick={handleClick}
            sx={{
              "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
              //bgcolor: "#9dc6d8",
              bgcolor: "#5ba7d5",
            }}
          >
            Lazy button &nbsp;&nbsp;
            <SendIcon />
          </Button>
        </Box>
      )}
      {!showLazyButton && (
        <Box
          //justifyContent={"center"}
          //alignContent={"center"}
          id="lazyimage"
          align={"center"}
          //display="none"
          m={2}
          sx={{
            backgroundImage: `url(${lazyimage})`,
            //backgroundImage: { lazyimage },
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: 300,
            height: 300,
            //display: "none",
          }}
        ></Box>
      )}
    </Box>
  );
};

export default LazyButton;
