import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  MenuItem,
  InputLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { roleOptions } from "../../../utils/UsedProps";
import { useNavigate } from "react-router-dom";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

let changedFields = [];
const FULLNAME_REGEX = /^[a-zA-Z\s]+$/;
const USER_REGEX = /^(?=[a-zA-Z]+[\w]*@[a-zA-Z]+[a-zA-Z-]+\.[a-zA-Z.]+$)/;
/* requires one lowercase letter, one uppercase letter, one digit 
and one special character minlength 8, maxlength 15
*/
const PWD_REGEX =
  /^(?=[\w!@#$%]*[a-z]+)(?=[\w!@#$%]*[A-Z]+)(?=[\w!@#$%]*[0-9]+)(?=[\w!@#$%]*[!@#$%]+)([\w!@#$%]{8,15})$/;

const UsersView = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const initializeData = Object.freeze({
    fullname: "",
    email: "",
    password: "",
    role: "",
  });

  const [formData, setFormData] = useState(initializeData);
  const [initialFormData, setInitialFormData] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/controllerapi/users/viewdata/${id}`
        );
        setFormData(resp.data);
        setInitialFormData(resp.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const [validFullName, setValidFullName] = useState(false);
  const [fullNameFocus, setFullNameFocus] = useState(false);

  //const [user, setUser] = useState("");
  const [validUser, setValidUser] = useState(false);
  //whether we set focus on the user field or not
  const [userFocus, setUserFocus] = useState(false);

  //const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  //confirm password field
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  useEffect(() => {
    let fullName = formData.fullname;
    const result = FULLNAME_REGEX.test(fullName);
    //console.log("Fullname res=", result);
    //console.log("FullName=", fullName);
    setValidFullName(result);
  }, [formData.fullname]);

  /* applied to the username field. Since we pass user 
  value to dependency array, it checks it everytime the value changes.
*/
  useEffect(() => {
    let user = formData.email;
    const result = USER_REGEX.test(user);
    //console.log(result);
    //console.log(user);
    setValidUser(result);
  }, [formData.email]);

  /* applied to the password field and confirm pwd field. 
The advantage of having both in the same useEffect hook is that 
anytime one of them changes, it checks both fields and allows the 
fields to be in sync with each other.
*/
  useEffect(() => {
    let pwd = formData.password;
    const result = PWD_REGEX.test(pwd);
    //console.log(result);
    //console.log(pwd);
    setValidPwd(result);
    /*set variable match to pwd value and test it against confirm pwd
    field to return a boolean in the end.
    */
    const match = pwd === matchPwd;
    setValidMatch(match);
    //console.log("Match=" + validMatch);
  }, [formData.password, matchPwd]);

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
    if (
      initialFormData[e.target.name] !== e.target.value &&
      changedFields.indexOf(e.target.name) === -1
    ) {
      changedFields.push(e.target.name);
      //console.log("changedFields local=", changedFields);
    }
  };

  //console.log("Formdata=", formData);

  const updateData = async () => {
    //console.log("changedFields=", changedFields);
    let updatedData = {};
    changedFields.forEach((val) => {
      updatedData[val] = formData[val];
    });

    //console.log("Updated data= ", updatedData);

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/controllerapi/biology/viewdata/${id}/`,
        updatedData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      let successMsg = "Post (" + formData.email + ") successfully updated!";
      navigate("/controller/users", { state: { successMsg: successMsg } });
    } catch (error) {
      //alert("Failed to update post!");
      setErrorMsg("Failed to update post! Please correct the errors.");
      //console.log("Response=\n", error.response.data);
      if (error.response.data !== null) {
        setErrorData(error.response.data);
      }
    }
  };

  //console.log(errorData);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateData();
    //console.log(formData);
  };

  return (
    <>
      <div className={errorMsg ? "errmsg" : "hide"}>
        <span>{errorMsg}</span>
      </div>
      <Stack
        display="flex"
        flexDirection={"row"}
        //justifyContent={"center"}
        //align={"center"}
      >
        <Box display={"flex"} flex={6} m={2} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              flex={6}
              variant="h6"
              justifyContent={"center"}
              align="center"
            >
              Update user
            </Typography>
          </Box>

          <Box
            //display={"flex"}
            display={"block"}
            spacing={1}
            m={2}
            //justifyContent={"center"}
            // align={"center"}
            fullWidth
            //width="50vw"
          >
            <form id="createForm" onSubmit={handleSubmit} width="inherit">
              <Box width="inherit">
                <div className={errorData.fullname ? "errdetail" : "hide"}>
                  <span>{errorData.fullname}</span>
                </div>

                <span className={validFullName ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span
                  className={
                    validFullName || !formData.fullname ? "hide" : "invalid"
                  }
                >
                  <CloseIcon />
                </span>

                <TextField
                  fullWidth
                  label="Fullname"
                  name="fullname"
                  margin="normal"
                  value={formData.fullname}
                  onChange={handleChange}
                  onFocus={() => setFullNameFocus(true)}
                  onBlur={() => setFullNameFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="fullNamenote"
                  className={
                    fullNameFocus && formData.fullname && !validFullName
                      ? "instructions"
                      : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    <br />
                    Only letters and spaces allowed.
                  </p>
                </span>
              </Box>

              <Box width="inherit">
                <div className={errorData.email ? "errdetail" : "hide"}>
                  <span>{errorData.email}</span>
                </div>
                <span className={validUser ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span
                  className={validUser || !formData.email ? "hide" : "invalid"}
                >
                  <CloseIcon />
                </span>

                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  margin="normal"
                  value={formData.email}
                  required
                  onChange={handleChange}
                  onFocus={() => setUserFocus(true)}
                  onBlur={() => setUserFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="uidnote"
                  className={
                    userFocus && formData.email && !validUser
                      ? "instructions"
                      : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    <br />
                    Must be between 4 to 20 characters.
                    <br />
                    Must begin with a letter.
                    <br />
                    Only letters, numbers, underscores, hyphens, dots allowed.
                  </p>
                </span>
              </Box>
              <Box width="inherit">
                <div className={errorData.password ? "errdetail" : "hide"}>
                  <span>{errorData.password}</span>
                </div>
                <span className={validPwd ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span
                  className={
                    validPwd || !formData.password ? "hide" : "invalid"
                  }
                >
                  <CloseIcon />
                </span>
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  name="password"
                  margin="normal"
                  required
                  valu={formData.password}
                  onChange={handleChange}
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="pwdnote"
                  className={
                    pwdFocus && formData.password && !validPwd
                      ? "instructions"
                      : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    Must be between 8 to 20 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character. <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>
                    <span aria-label="at symbol">@</span>
                    <span aria-label="hashtag">#</span>
                    <span aria-label="dollar sign">$</span>
                    <span aria-label="percent">%</span>
                    <br />
                  </p>
                </span>
              </Box>

              <Box width="inherit">
                <div className={errorData.password ? "errdetail" : "hide"}>
                  <span>{errorData.password}</span>
                </div>
                <span className={validPwd && validMatch ? "valid" : "hide"}>
                  <DoneAllIcon />
                </span>

                <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                  <CloseIcon />
                </span>
                <TextField
                  type="password"
                  fullWidth
                  label="Confirm password"
                  name="cnfpassword"
                  margin="normal"
                  required
                  onChange={(e) => setMatchPwd(e.target.value)}
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
                <span
                  id="confirmnote"
                  className={
                    matchFocus && !validMatch ? "instructions" : "hide"
                  }
                >
                  <p>
                    <InfoIcon />
                    Must match the Password input field.
                    <br />
                  </p>
                </span>
              </Box>

              <Box width={"inherit"}>
                <FormControl fullWidth>
                  <InputLabel id="role" InputLabelProps={{ shrink: true }}>
                    Role
                  </InputLabel>
                  <Select
                    //defaultValue={initialValues.status}
                    labelId="role"
                    id="role"
                    name="role"
                    label="role"
                    value={formData.role}
                  >
                    {roleOptions.map((role) => {
                      return (
                        <MenuItem
                          name={role.name}
                          value={role.value}
                          //defaultValue={role === "AccountUser" ? role : ""}
                          defaultValue={201}
                          //selected={role === "AccountUser" ? role : ""}
                        >
                          {role.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Date posted"
                  name="date_posted"
                  value={formData.date_created}
                  margin="normal"
                  readonly
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </Box>
              <Box mt={3} justifyContent={"center"} align={"center"}>
                <Button
                  disabled={
                    !validFullName || !validUser || !validPwd || !validMatch
                      ? true
                      : false
                  }
                  mt={3}
                  //fullWidth

                  type="submit"
                  variant="contained"
                  sx={{
                    "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                    //bgcolor: "#9dc6d8",
                    bgcolor: "#5ba7d5",
                  }}
                  onClick={handleSubmit}
                >
                  Update user
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default UsersView;

/*
<TextField
                fullWidth
                label="Current image"
                name="currentImage"
                value={initialFormData.image}
                //onChange={handleChange}
                margin="normal"
                required
                readonly
                InputLabelProps={{ shrink: true }}
              ></TextField>
let selected = false;
  const getSelected = (status) => {
    /console.log("Initial=", initialFormData.status);
    console.log("Status=", status);
    if (formData.status === status) {
      selected = true;
    } else {
      selected = false;
    }
    console.log("Selected=", selected);
  };


  try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/controllerapi/biology/viewdata/${id}/`,
        {
          title: formData.title,
          excerpt: formData.excerpt,
          content1: formData.content1,
          content2: formData.content2,
          references: formData.references,
          status: formData.status,
          image: formData.image,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      alert("Post successfully updated!");
      navigate("/controller/biology");
    } catch (error) {
      alert("Failed to update post!");
      console.log(error);
    }
  };
*/
