import { Box, Typography } from "@mui/material";
import React from "react";

const Notfound = () => {
  return (
    <Box justifyContent={"center"} align="center" m={3} sx={{ color: "red" }}>
      <Typography variant="h4">404 Page not found!</Typography>
    </Box>
  );
};

export default Notfound;
