import React from "react";
import { createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { JWT_REGEX, EMAIL_REGEX, ROLE_REGEX } from "../utils/UsedProps";

const AuthContext = createContext({});
export default AuthContext;

export const AuthProvider = ({ children }) => {
  const validated_token = () => {
    if (localStorage.getItem("authToken") === null) {
      return null;
    } else {
      let token = localStorage.getItem("authToken");
      /*
      console.log(
        "Token=",
        token,
        typeof token,
        JWT_REGEX.test(JSON.parse(token))
      );
      */
      if (JWT_REGEX.test(JSON.parse(token))) {
        return token;
      } else {
        return null;
      }
    }
  };
  const [authToken, setAuthToken] = useState(validated_token());

  const validated_email = (token) => {
    let mailid = jwtDecode(token).email;
    if (EMAIL_REGEX.test(mailid)) {
      return mailid;
    } else {
      return null;
    }
  };
  const [email, setEmail] = useState(() =>
    authToken ? validated_email(authToken) : null
  );

  const validated_role = (token) => {
    let role = jwtDecode(token).role;
    if (ROLE_REGEX.test(role)) {
      return role;
    } else {
      return null;
    }
  };

  const [role, setRole] = useState(
    authToken ? validated_role(authToken) : null
  );

  //console.log("AuthContext=", authToken, email, role);

  let contextData = {
    email: email,
    setEmail: setEmail,
    role: role,
    setRole: setRole,
    authToken: authToken,
    setAuthToken: setAuthToken,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

//attention: we need to export the AuthContext, not AuthProvider.

//export default AuthProvider;
/*
<AuthContext.Provider value={{ auth, setAuth }}>
*/
