import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo_text from "../../static/logo_text_white_new.png";
import avatar from "../../static/avatar_new.jpg";
import { ListItemText, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

import { Link, styled } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

import { useState } from "react";
import {
  menu_science,
  menu_md_home,
  menu_md_others,
  menu_xs_home,
  menu_xs_others,
  menu_admin,
  menu_account,
} from "../../utils/UsedProps";
import Menuxs from "../menuxs/Menuxs";
import useAuth from "../../context/useAuth";

function Navbar() {
  let { email, role } = useAuth();

  //Menu is a 'MuiList-root' class component. So, we can override its style.
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        //https://github.com/mui/material-ui/issues/29703
        //This is the only styling that works for individual menu items.
        styleOverrides: {
          root: {
            backgroundColor: "#32333d",
          },
        },
      },
      MuiList: {
        //this is for the dropdown's background.
        styleOverrides: {
          root: {
            //backgroundColor: "black",
            backgroundColor: "#32333d",
            color: "white",
          },
        },
      },
    },
  });

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElScience, setAnchorElScience] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenScienceMenu = (event) => {
    setAnchorElScience(event.currentTarget);
    //console.log(event.currentTarget);
  };

  const handleCloseScienceMenu = () => {
    //console.log("*", anchorElUser, "*");
    setAnchorElScience(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    //console.log(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    //console.log("*", anchorElUser, "*");
    setAnchorElUser(null);
  };

  const LogoButtonXs = styled(IconButton)(({ theme }) => ({
    flex: 6,
    justifyContent: "center",
    alignItems: "center",
    height: "44px",
    maxWidth: "80%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  }));

  const LogoButtonMd = styled(IconButton)(({ theme }) => ({
    justifyContent: "left",
    padding: "4px",
    height: "64px",
    maxWidth: "30%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const navigate = useNavigate();

  //console.log("Navbar2, email=", email, role);

  return (
    <>
      <AppBar position="sticky" className={"menubg"}>
        <Toolbar variant="dense" disableGutters>
          <Box
            ml={1}
            sx={{
              flexGrow: 0,
              flex: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              //flex={1}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <ThemeProvider theme={theme}>
              <Menu
                id="menu_xs_side"
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ display: { xs: "block", md: "none" } }}
                onClose={handleCloseNavMenu}
              >
                {menu_xs_home.map((item) => {
                  return (
                    <MenuItem
                      component={NavLink}
                      to={item.url}
                      key={item.Name}
                      onClick={handleCloseNavMenu}
                    >
                      <ListItemText> {item.Name}</ListItemText>
                    </MenuItem>
                  );
                })}

                <Menuxs onClose={handleCloseNavMenu} />

                {menu_xs_others.map((item) => {
                  return (
                    <MenuItem
                      component={NavLink}
                      to={item.url}
                      key={item.Name}
                      onClick={handleCloseNavMenu}
                    >
                      <ListItemText> {item.Name}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Menu>
            </ThemeProvider>
          </Box>

          <LogoButtonXs href="/home">
            <img src={logo_text} alt="logo" height="100%" align="center" />
          </LogoButtonXs>

          <LogoButtonMd href="/home">
            <img src={logo_text} alt="logo" height="100%" />
          </LogoButtonMd>

          <Box
            justifyContent="center"
            alignItems={"center"}
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {menu_md_home.map((menuh) => {
              return (
                //console.log(page)
                <Button
                  key={menuh.Name}
                  onClick={() => {
                    //handleCloseNavMenu();
                    navigate(`${menuh.url}`);
                  }}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    textTransform: "none", // otherwise all button text is in caps by default.
                  }}
                >
                  {menuh.Name}
                </Button>
              );
            })}

            {menu_science.map((menus) => {
              return (
                <Box
                  flex={2}
                  className={"menubg"}
                  sx={{
                    flexGrow: 0,
                    //bgcolor: "black",
                  }}
                >
                  <Button
                    //width={100}
                    //sx={{ p: 0 }}
                    sx={{ textTransform: "none" }}
                    onClick={handleOpenScienceMenu}
                  >
                    <Typography
                      //variant="body2"
                      color="white"
                      sx={{
                        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                        fontWeight: 500,
                        fontSize: "0.875rem",
                      }}
                    >
                      {menus.Name}
                    </Typography>
                  </Button>
                  <ThemeProvider theme={theme}>
                    <Menu
                      sx={{
                        mt: "45px",
                        display: { xs: "none", md: "block" },
                      }}
                      //style={{ backgroundColor: "black" }}
                      id="menu-appbar"
                      anchorEl={anchorElScience}
                      anchorOrigin={{
                        //vertical: "top",
                        //vertical: "center",
                        vertical: "bottom",
                        //horizontal: "right",
                        //horizontal: "left",
                        horizontal: "center",
                      }}
                      keepMounted
                      transformOrigin={{
                        //vertical: "top",
                        vertical: "center",
                        //vertical: "top",
                        //horizontal: "right",

                        horizontal: "center",
                      }}
                      open={Boolean(anchorElScience)}
                      onClose={handleCloseScienceMenu}
                    >
                      {menus.submenu.map((submen) => {
                        return (
                          <MenuItem
                            component={NavLink}
                            to={submen.url}
                            key="{pagesub.Name}"
                            onClick={handleCloseScienceMenu}
                            //sx={{ bgcolor: "black" }}
                            //className="menubg"
                          >
                            <Typography
                              textAlign="center"
                              //className="menubg"
                              //variant="body2"
                              sx={{
                                //bgcolor: "black",
                                color: "white",
                                //width: "inherit",
                                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                                fontWeight: 500,
                                fontSize: "0.875rem",
                              }}
                            >
                              {submen.Name}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </ThemeProvider>
                </Box>
              );
            })}

            {menu_md_others.map((menuo) => {
              return (
                //console.log(page)
                <Button
                  key={menuo.Name}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`${menuo.url}`);
                  }}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    textTransform: "none", // otherwise all button text is in caps by default.
                  }}
                >
                  {menuo.Name}
                </Button>
              );
            })}

            {role === 101
              ? menu_admin.map((menuad) => {
                  return (
                    //console.log(page)
                    <Button
                      key={menuad.Name}
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(`${menuad.url}`);
                      }}
                      sx={{
                        my: 2,
                        color: "white",
                        display: "block",
                        textTransform: "none", // otherwise all button text is in caps by default.
                      }}
                    >
                      {menuad.Name}
                    </Button>
                  );
                })
              : ""}
          </Box>

          {email ? (
            <>
              {menu_account.map((menuc) => {
                return (
                  <Box
                    mr={1}
                    p={0}
                    mb={0}
                    mt={0}
                    flex={1}
                    sx={{
                      flexGrow: 0,
                      //display: { xs: "none", md: "flex" }
                      //bgcolor: "black",
                    }}
                  >
                    <Button
                      //width={100}
                      sx={{ p: 0, m: 0, textTransform: "none" }}
                      onClick={handleOpenUserMenu}
                    >
                      <IconButton onClick={() => {}} sx={{ m: 0, p: 0 }}>
                        <Avatar
                          variant="rounded"
                          component="string"
                          p={0}
                          m={0}
                          sx={{
                            bgcolor: "#5ba7d5",
                            width: "64px",
                            height: "34px",
                          }}
                        >
                          <div>
                            <p align="center" style={{ fontSize: "0.7em" }}>
                              {menuc.Name}
                            </p>
                          </div>
                        </Avatar>
                      </IconButton>
                    </Button>
                    <ThemeProvider theme={theme}>
                      <Menu
                        sx={{
                          mt: "45px",
                          //display: { xs: "none", md: "block" },
                          display: { md: "block" },
                        }}
                        //style={{ backgroundColor: "black" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          //vertical: "top",
                          //vertical: "center",
                          vertical: "bottom",
                          //horizontal: "right",
                          //horizontal: "left",
                          horizontal: { xs: "left", md: "center" },
                        }}
                        keepMounted
                        transformOrigin={{
                          //vertical: "top",
                          vertical: "center",
                          //vertical: "top",
                          //horizontal: "right",

                          horizontal: { xs: "left", md: "center" },
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {menuc.submenu.map((submenc) => {
                          return (
                            <MenuItem
                              component={NavLink}
                              to={submenc.url}
                              key="{pagesub.Name}"
                              onClick={handleCloseUserMenu}
                              //sx={{ bgcolor: "black" }}
                            >
                              <Typography
                                textAlign="center"
                                //variant="body2"
                                sx={{
                                  //bgcolor: "black",
                                  color: "white",
                                  //width: "inherit",
                                  fontFamily:
                                    "Roboto,Helvetica,Arial,sans-serif",
                                  fontWeight: 500,
                                  fontSize: "0.875rem",
                                }}
                              >
                                {submenc.Name}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </ThemeProvider>
                  </Box>
                );
              })}
            </>
          ) : (
            <>
              <Box sx={{ flex: 1, flexGrow: 0 }} mr={1}>
                <Link
                  sx={{ textDecoration: "none", color: "white" }}
                  href="/signin"
                >
                  Signin
                </Link>
              </Box>
              <Box sx={{ flexGrow: 0 }} mr={1}>
                <Tooltip title="Hi, I'm Preethi P.">
                  <IconButton onClick={() => {}} sx={{ p: 0 }}>
                    <Avatar alt="Preethi" src={avatar} />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
export default Navbar;
