import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  MenuItem,
  InputLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { statusOptions } from "../../../utils/UsedProps";
import { useNavigate, useLocation } from "react-router-dom";

let changedFields = [];

const BiologyView = () => {
  let { id } = useParams();

  const navigate = useNavigate();
  const loc = useLocation();
  let currUrl = loc.pathname;

  const initializeData = Object.freeze({
    title: "",
    excerpt: "",
    content1: "",
    content2: "",
    references: "",
    status: "",
    image: null,
    image_caption: "",
    like_count: 0,
    date_created: null,
  });

  const [formData, setFormData] = useState(initializeData);
  const [initialFormData, setInitialFormData] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/controllerapi/biology/viewdata/${id}`
        );
        /*
        let post_date = new Date(resp.data.date_created);
        resp.data.date_created = post_date
          .toLocaleString("en_GB")
          .replace(",", "");
        */
        setFormData(resp.data);
        setInitialFormData(resp.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleImageChange = (e) => {
    e.preventDefault();
    //const formData = new FormData()
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
    if (
      initialFormData[e.target.name] !== e.target.value &&
      changedFields.indexOf(e.target.name) === -1
    ) {
      changedFields.push(e.target.name);
      //console.log("changedFields local=", changedFields);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (
      initialFormData[e.target.name] !== e.target.value &&
      changedFields.indexOf(e.target.name) === -1
    ) {
      changedFields.push(e.target.name);
      //console.log("changedFields local=", changedFields);
    }
  };

  //console.log("Formdata=", formData);

  const updateData = async () => {
    //console.log("changedFields=", changedFields);
    let updatedData = {};
    changedFields.forEach((val) => {
      updatedData[val] = formData[val];
    });

    //console.log("Updated data= ", updatedData);

    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/controllerapi/biology/viewdata/${id}/`,
        updatedData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      let successMsg = "Post (" + formData.title + ") successfully updated!";
      navigate("/controller/biology", { state: { successMsg: successMsg } });
    } catch (error) {
      //alert("Failed to update post!");
      setErrorMsg("Failed to update post! Please correct the errors.");
      //console.log("Response=\n", error.response.data);
      if (error.response.data !== null) {
        setErrorData(error.response.data);
      }
    }
  };

  //console.log(errorData);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateData();
    //console.log(formData);
  };

  return (
    <>
      <div className={errorMsg ? "errmsg" : "hide"}>
        <span>{errorMsg}</span>
      </div>
      <Stack display="flex" flexDirection={"row"}>
        <Box display={"flex"} flex={6} m={2} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              flex={6}
              variant="h6"
              justifyContent={"center"}
              align="center"
            >
              View and update record
            </Typography>
            <Button
              flex={2}
              variant="contained"
              sx={{
                "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                //bgcolor: "#9dc6d8",
                bgcolor: "#5ba7d5",
              }}
              href={`${currUrl}/history`}
            >
              History
            </Button>
          </Box>

          <Box
            //display={"block"}
            display={"flex"}
            spacing={1}
            m={2}
            justifyContent={"center"}
            fullWidth
          >
            <form onSubmit={handleSubmit} width="inherit">
              <div className={errorData.title ? "errdetail" : "hide"}>
                <span>{errorData.title}</span>
              </div>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                margin="normal"
                required
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <TextField
                fullWidth
                label="Slug"
                value={formData.slug}
                readonly
                margin="normal"
                required
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <div className={errorData.excerpt ? "errdetail" : "hide"}>
                <span>{errorData.excerpt}</span>
              </div>
              <TextField
                fullWidth
                label="Excerpt"
                name="excerpt"
                value={formData.excerpt}
                onChange={handleChange}
                margin="normal"
                required
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <div className={errorData.content1 ? "errdetail" : "hide"}>
                <span>{errorData.content1}</span>
              </div>
              <TextField
                fullWidth
                label="Content1"
                name="content1"
                value={formData.content1}
                onChange={handleChange}
                margin="normal"
                required
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <Box direction="column">
                <div>
                  <label htmlFor="currentImage">Current image: </label>
                  <a
                    name="currentImage"
                    href={initialFormData.image}
                    //onChange={handleChange}
                    margin="normal"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    required
                    //readonly
                  >
                    {initialFormData.image}
                  </a>
                </div>
                <div className={errorData.image ? "errdetail" : "hide"}>
                  <span>{errorData.image}</span>
                </div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <label htmlFor="image">Image</label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    //value={formData.image}
                    accept="image/*"
                    onChange={handleImageChange}
                    required
                  ></input>
                </div>
              </Box>

              <TextField
                fullWidth
                label="Image caption"
                name="image_caption"
                margin="normal"
                value={formData.image_caption}
                onChange={handleChange}
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <TextField
                fullWidth
                label="Content2"
                name="content2"
                value={formData.content2}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <TextField
                fullWidth
                label="References"
                name="references"
                value={formData.references}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              ></TextField>
              <FormControl fullWidth>
                <InputLabel id="status" InputLabelProps={{ shrink: true }}>
                  Status
                </InputLabel>
                <Select
                  //defaultValue={initialValues.status}
                  labelId="status"
                  id="status"
                  name="status"
                  value={formData.status}
                  //value={() => }
                  label="Status"
                  onChange={handleChange}
                >
                  {statusOptions.map((status) => {
                    return (
                      <MenuItem
                        name={status}
                        value={status}
                        //selected={getSelected(status)}
                      >
                        {status}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Likes"
                name="like_count"
                value={formData.like_count}
                margin="normal"
                readonly
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <TextField
                fullWidth
                label="Date posted"
                name="date_posted"
                value={formData.date_posted}
                margin="normal"
                readonly
                InputLabelProps={{ shrink: true }}
              ></TextField>

              <Box mt={3} justifyContent={"center"} align={"center"}>
                <Button
                  mt={3}
                  //fullWidth

                  type="submit"
                  variant="contained"
                  sx={{
                    "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                    //bgcolor: "#9dc6d8",
                    bgcolor: "#5ba7d5",
                  }}
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default BiologyView;

/*
<TextField
                fullWidth
                label="Current image"
                name="currentImage"
                value={initialFormData.image}
                //onChange={handleChange}
                margin="normal"
                required
                readonly
                InputLabelProps={{ shrink: true }}
              ></TextField>
let selected = false;
  const getSelected = (status) => {
    //console.log("Initial=", initialFormData.status);
    console.log("Status=", status);
    if (formData.status === status) {
      selected = true;
    } else {
      selected = false;
    }
    console.log("Selected=", selected);
  };


  try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/controllerapi/biology/viewdata/${id}/`,
        {
          title: formData.title,
          excerpt: formData.excerpt,
          content1: formData.content1,
          content2: formData.content2,
          references: formData.references,
          status: formData.status,
          image: formData.image,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      alert("Post successfully updated!");
      navigate("/controller/biology");
    } catch (error) {
      alert("Failed to update post!");
      console.log(error);
    }
  };
*/
