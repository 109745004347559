import { Box } from "@mui/material";
import React from "react";
import LazyButton from "../lazybutton/LazyButton";

const Rightbar = () => {
  return (
    <Box flex={1} flexDirection={"column"} sx={{ padding: 3 }}>
      <LazyButton />
    </Box>
  );
};

export default Rightbar;

/*
<Box id="lazybutton" align={"center"} m={2}>
        <Button
          href={"#"}
          variant={"contained"}
          //align={"center"}
          justifyContent={"center"}
          onClick={handleClick}
          sx={{
            "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
            //bgcolor: "#9dc6d8",
            bgcolor: "#5ba7d5",
          }}
        >
          Lazy button &nbsp;&nbsp;
          <SendIcon />
        </Button>
      </Box>

 <Box
        //justifyContent={"center"}
        //alignContent={"center"}
        id="lazyimage"
        align={"center"}
        display="none"
        m={2}
        sx={{
          backgroundImage: `url(${lazyimage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width: 300,
          height: 300,
          //display: "none",
        }}
      ></Box>


 <Avatar
          ref={lazyImageRef}
          variant="square"
          src={lazyimage}
          sx={{
            bgcolor: "transparent",
            width: 500,
            height: 500,
            "&:hover": { opacity: 0.8, "&:hover": `all 0.5s ease` },
            loading: "lazy",
          }}
          ></Avatar>
*/
