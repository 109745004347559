import { Avatar, Box, CardMedia, Typography, IconButton } from "@mui/material";
import React from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
import { useEffect, useState } from "react";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import useAuth from "../../context/useAuth";

const Humor = () => {
  const { email } = useAuth();
  const navigate = useNavigate();
  const [humorDetails, setHumorDetails] = useState([]);
  const [humorImages, setHumorImages] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  let { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/humorapi/humorposts/${slug}`
        );
        setHumorDetails(resp.data);
        setLikeCount(resp.data.like_count);
        setHumorImages(resp.data.images);
        //console.log(resp.data);
        //console.log(resp.data.images);
        //console.log(humorDetails);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    //console.log(humorImages);
    //console.log(humorDetails);
  }, []);

  const handleLike = async () => {
    if (email) {
      //console.log(likeCount);
      setLikeCount(likeCount + 1);
      //console.log(likeCount);
      try {
        const like_resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/humorapi/humorlikes/${humorDetails.id}`
        );
        //console.log(like_resp);
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/signin");
    }
  };

  return (
    <Box width="100%">
      <Box
        display={"flex"}
        justifyContent={"center"}
        align={"center"}
        mt={2}
        mb={2}
      >
        <Avatar
          variant="rounded"
          component="string"
          sx={{
            bgcolor: "#5ba7d5",
            width: { xs: 300, md: 500 },
            height: 100,
          }}
        >
          <Typography
            variant={"h3"}
            align="center"
            sx={{
              color: "black",
              fontFamily: "Splash, cursive",
            }}
          >
            Humo(u?)r
          </Typography>
        </Avatar>
      </Box>
      <Box>
        <Typography
          variant={"h4"}
          align="center"
          sx={{
            color: "#5ba7d5",
            fontFamily: "Single Day, cursive",
          }}
        >
          {humorDetails.title}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={"body1"}
          align="center"
          sx={{
            color: "#5ba7d5",
            fontFamily: "Single Day, cursive",
            fontStyle: "italic",
          }}
        >
          {
            humorDetails?.date_posted
              ? new Date(humorDetails?.date_posted).toDateString()
              : null
            //new Date(humorDetails.date_posted).toDateString()
          }
        </Typography>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"left"}
        alignContent={"center"}
        opacity={0.8}
      >
        <IconButton className={"iconbuttonbg"} onClick={handleLike}>
          <Typography
            p={0.5}
            align={"left"}
            variant="body2"
            display={"flex"}
            sx={{ opacity: 0.8, fontStyle: "italic" }}
          >
            Like
          </Typography>
          <ThumbUpAltIcon />
        </IconButton>
        {likeCount > 0 ? (
          <Typography
            pt={1.5}
            variant="body2"
            display={"flex"}
            sx={{ opacity: 0.8, fontStyle: "italic" }}
          >
            {likeCount}
          </Typography>
        ) : (
          ""
        )}
      </Box>
      <Box>
        <Typography
          variant={"h6"}
          align={"justify"}
          sx={{
            //color: "#5ba7d5",
            fontFamily: "Single Day, cursive",
            fontStyle: "italic",
          }}
        >
          <ReactMarkdown
            remarkPlugins={[gfm]}
            rehypePlugins={[
              [
                rehypeExternalLinks,
                { target: "_blank", rel: "nofollow noopener noreferrer" },
              ],
            ]}
            children={humorDetails.excerpt}
          ></ReactMarkdown>
        </Typography>
      </Box>
      <Box>
        {humorDetails.note ? (
          <>
            <Typography
              variant="body1"
              align={"justify"}
              mt={4}
              align={"left"}
              justifyContent={"center"}
              sx={{
                fontWeight: "bold",
                //fontFamily: "Single Day, cursive"
              }}
            >
              Little note:
            </Typography>
            <Typography>
              <ReactMarkdown
                remarkPlugins={[gfm]}
                rehypePlugins={[
                  [
                    rehypeExternalLinks,
                    { target: "_blank", rel: "nofollow noopener noreferrer" },
                  ],
                ]}
                children={humorDetails.note}
              ></ReactMarkdown>
            </Typography>
          </>
        ) : (
          ""
        )}
      </Box>

      <Box>
        {humorImages.map((item, idx) => {
          return (
            <Box justifyContent={"center"} align={"center"} p={2}>
              <CardMedia
                //flex={1}
                component="Avatar"
                justifyContent={"center"}
                align={"center"}
                sx={{ backgroundPosition: "center" }}
              >
                <Avatar
                  variant="square"
                  justifyContent={"center"}
                  align={"center"}
                  position={"center"}
                  src={item.image}
                  //sx={{ height: 500, width: 500, mt: { xs: 0, md: 3 } }}
                  sx={{
                    height: { xs: 300, md: 500 },
                    width: { xs: 300, md: 500 },
                    mt: { xs: 0, md: 1 },
                  }}
                />
              </CardMedia>
            </Box>
          );
        })}
      </Box>
      <Box>
        {humorDetails.footnotes ? (
          <Box mt={2}>
            <Typography
              id="references"
              className={"contenth"}
              variant={"h6"}
              align={"center"}
              fontWeight={"bold"}
            >
              Footnotes
            </Typography>

            <Typography variant="body2" align={"justify"}>
              <ReactMarkdown
                remarkPlugins={[gfm]}
                rehypePlugins={[
                  [
                    rehypeExternalLinks,
                    { target: "_blank", rel: "nofollow noopener noreferrer" },
                  ],
                ]}
                children={humorDetails.footnotes}
              ></ReactMarkdown>
            </Typography>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default Humor;

/*
 {humorDetails.images.map((image, idx) => {
          return (
            <Box justifyContent={"center"} align={"center"} p={2}>
              <CardMedia
                //flex={1}
                component="Avatar"
                justifyContent={"center"}
                align={"center"}
                sx={{ backgroundPosition: "center" }}
              >
                <Avatar
                  variant="square"
                  justifyContent={"center"}
                  align={"center"}
                  position={"center"}
                  src={image}
                  //sx={{ height: 500, width: 500, mt: { xs: 0, md: 3 } }}
                  sx={{
                    height: { xs: 300, md: 500 },
                    width: { xs: 300, md: 500 },
                    mt: { xs: 0, md: 1 },
                  }}
                />
              </CardMedia>
            </Box>
          );
        })}
*/
