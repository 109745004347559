import {
  Avatar,
  Box,
  CardMedia,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
//import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import useAuth from "../../context/useAuth";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const Fiction = () => {
  const { email } = useAuth();
  const navigate = useNavigate();
  const [fictionDetails, setFictionDetails] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  let { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/fictionapi/fictionposts/${slug}`
        );
        setFictionDetails(resp.data);
        setLikeCount(resp.data.like_count);
        //console.log(resp.data);
        //console.log(resp.data.images);
        //console.log(humorDetails);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    //console.log(humorImages);
    //console.log(humorDetails);
  }, []);

  const handleLike = async () => {
    if (email) {
      //console.log(likeCount);
      setLikeCount(likeCount + 1);
      //console.log(likeCount);
      try {
        const like_resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/fictionapi/fictionlikes/${fictionDetails.id}`
        );
        //console.log(like_resp);
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/signin");
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        //justifyContent={"center"}
        //align={"center"}
        align={"justify"}
      >
        <Box justifyContent={"center"} align={"center"}>
          <Avatar
            variant="rounded"
            component="string"
            align="center"
            sx={{
              bgcolor: "#5ba7d5",
              width: { xs: 300, md: 500 },
              height: 100,
            }}
          >
            <Typography
              variant={"h3"}
              align="center"
              sx={{
                color: "black",
                fontFamily: "Splash, cursive",
              }}
            >
              Fiction
            </Typography>
          </Avatar>
        </Box>
        <Box>
          <Grid
            direction={"column"}
            container
            //justifyContent={"center"}
            //alignItems={"center"}
          >
            <Grid item xs mt={2}>
              <Box display={"flex"} flexDirection={"row"}>
                <Box p={{ md: 3, xs: 0 }}>
                  <CardMedia component="Avatar" flex={1} sx={{ float: "left" }}>
                    <Avatar
                      variant="square"
                      component="image"
                      src={fictionDetails.image}
                      sx={{
                        bgcolor: "transparent",
                        // width: { md: 200, xs: 150 },
                        //height: { md: 300, xs: 200 },
                        width: 200,
                        height: { md: 300, xs: 350 },
                        //height: 300,
                        loading: "lazy",
                      }}
                    ></Avatar>
                  </CardMedia>
                </Box>
                <Box align={"center"} flex={3} mt={6} ml={0.5}>
                  <Typography
                    variant={"h4"}
                    align="center"
                    sx={{
                      color: "#5ba7d5",
                      fontFamily: "Single Day, cursive",
                    }}
                  >
                    {fictionDetails.title}
                  </Typography>
                  <Typography
                    mt={1}
                    variant={"body1"}
                    align="center"
                    sx={{
                      color: "#5ba7d5",
                      fontFamily: "Single Day, cursive",
                      fontStyle: "italic",
                    }}
                  >
                    {
                      fictionDetails?.date_posted
                        ? new Date(fictionDetails?.date_posted).toDateString()
                        : null
                      //new Date(fictionDetails.date_posted).toDateString()
                    }
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignContent={"center"}
                    opacity={0.8}
                  >
                    <IconButton className={"iconbuttonbg"} onClick={handleLike}>
                      <Typography
                        p={1}
                        align={"center"}
                        variant="body2"
                        display={"flex"}
                        sx={{ opacity: 0.8, fontStyle: "italic" }}
                      >
                        Like
                      </Typography>
                      <ThumbUpAltIcon />
                    </IconButton>
                    {likeCount > 0 ? (
                      <Typography
                        pt={2}
                        variant="body2"
                        display={"flex"}
                        sx={{ opacity: 0.8, fontStyle: "italic" }}
                      >
                        {likeCount}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs>
              <Box ml={1}>
                <Typography
                  variant="h6"
                  //mt={1}
                  align={"left"}
                  justifyContent={"left"}
                  sx={{
                    fontStyle: "italic",
                    fontFamily: "Single Day, cursive",
                    color: "#5ba7d5",
                  }}
                >
                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    rehypePlugins={[
                      [
                        rehypeExternalLinks,
                        {
                          target: "_blank",
                          rel: "nofollow noopener noreferrer",
                        },
                      ],
                    ]}
                    children={fictionDetails.summary}
                  ></ReactMarkdown>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs>
              <Typography variant="body1" m={3} sx={{ lineHeight: "1.8em" }}>
                <ReactMarkdown
                  children={fictionDetails.content1}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[
                    [
                      rehypeExternalLinks,
                      { target: "_blank", rel: "nofollow noopener noreferrer" },
                    ],
                  ]}
                />
              </Typography>
              <Typography variant="body1" m={3} sx={{ lineHeight: "1.8em" }}>
                <ReactMarkdown
                  children={fictionDetails.content2}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[
                    [
                      rehypeExternalLinks,
                      { target: "_blank", rel: "nofollow noopener noreferrer" },
                    ],
                  ]}
                />
              </Typography>
            </Grid>
            {fictionDetails.footnotes ? (
              <Grid item xs mt={3} id="footnotes">
                <Box>
                  <Typography
                    id="footnotes"
                    className={"contenth"}
                    variant={"h6"}
                    align="center"
                    fontWeight={"bold"}
                  >
                    Footnotes
                  </Typography>

                  <Typography variant="body2">
                    <ReactMarkdown
                      remarkPlugins={[gfm]}
                      rehypePlugins={[
                        [
                          rehypeExternalLinks,
                          {
                            target: "_blank",
                            rel: "nofollow noopener noreferrer",
                          },
                        ],
                      ]}
                      children={fictionDetails.footnotes}
                    ></ReactMarkdown>
                  </Typography>
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Fiction;
