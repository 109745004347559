import React from "react";
import { Stack, Box } from "@mui/material";
import Sidemenu from "./Sidemenu";
import FictionHistory from "./fiction/FictionHistory";
import HumorHistory from "./humor/HumorHistory";
import BiologyHistory from "./biology/BiologyHistory";
import AiHistory from "./ai/AiHistory";

const MainHistory = ({ origin }) => {
  return (
    <Stack display="flex" flexDirection={"row"}>
      <Box flex={1}>
        <Sidemenu />
      </Box>
      <Box flex={6} m={2}>
        {origin === "biology" ? (
          <BiologyHistory origin={origin} />
        ) : origin === "ai" ? (
          <AiHistory origin={origin} />
        ) : origin === "fiction" ? (
          <FictionHistory origin={origin} />
        ) : origin === "humor" ? (
          <HumorHistory origin={origin} />
        ) : (
          ""
        )}
      </Box>
    </Stack>
  );
};

export default MainHistory;
