import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from "@mui/icons-material/Send";
import scenery from "../../static/scenery.jpg";
//import { Form } from "react-router-dom";
import axios from "axios";

const FULLNAME_REGEX = /^[a-zA-Z\s]+$/;
const USER_REGEX = /^(?=[a-zA-Z]+[\w]*@[a-zA-Z]+[a-zA-Z-]+\.[a-zA-Z.]+$)/;

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sendStatus, setSendStatus] = useState("Send message");

  const [validFullName, setValidFullName] = useState(false);
  const [fullNameFocus, setFullNameFocus] = useState(false);

  const [validUser, setValidUser] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  useEffect(() => {
    let fullName = name;
    if (fullName === "") {
      setValidFullName(true);
    } else {
      const result = FULLNAME_REGEX.test(fullName);
      //console.log("Fullname res=", result);
      //console.log("FullName=", fullName);
      //console.log("Fullnamefocus=", fullNameFocus);
      setValidFullName(result);
    }
  }, [name]);

  /* applied to the username field. Since we pass user 
  value to dependency array, it checks it everytime the value changes.
*/
  useEffect(() => {
    let user = email;
    const result = USER_REGEX.test(user);
    //console.log(result);
    //console.log(user);
    //console.log("emal focus=", userFocus);
    setValidUser(result);
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendStatus("Sending...");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/contactapi/contact`,
        {
          name: name,
          email: email,
          message: message,
        }
      );

      setName("");
      setEmail("");
      setMessage("");
      setSendStatus("Send message");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      m={3}
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box
        p={2}
        //spacing={2}
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        width={"70vw"}
        sx={{ border: "2px solid #5ba7d5", borderRadius: 2, boxShadow: 4 }}
      >
        <Typography
          variant="h4"
          align="center"
          m={3}
          sx={{ fontFamily: "Single Day, cursive" }}
        >
          Contact me
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          //pr={10}
          //pl={10}
        >
          <img
            src={scenery}
            alt="contact_me"
            //width={500}
            //fullWidth
            width="100%"
            m={3}
          />
        </Box>

        <Box display={"block"} justifyContent={"center"} fullWidth>
          <form onSubmit={handleSubmit} width="inherit">
            <span className={validFullName && name !== "" ? "valid" : "hide"}>
              <DoneAllIcon />
            </span>

            <span className={validFullName || !name ? "hide" : "invalid"}>
              <CloseIcon />
            </span>
            <TextField
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onFocus={() => setFullNameFocus(true)}
              onBlur={() => setFullNameFocus(false)}
              margin="normal"
              required
            ></TextField>
            <span
              id="fullNamenote"
              className={
                fullNameFocus && name && !validFullName
                  ? "instructions"
                  : "hide"
              }
            >
              <p>
                <InfoIcon />
                <br />
                Only letters and spaces allowed.
              </p>
            </span>

            <span className={validUser ? "valid" : "hide"}>
              <DoneAllIcon />
            </span>

            <span className={validUser || !email ? "hide" : "invalid"}>
              <CloseIcon />
            </span>
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setUserFocus(true)}
              onBlur={() => setUserFocus(false)}
              margin="normal"
              required
            ></TextField>
            <span
              id="uidnote"
              className={
                userFocus && email && !validUser ? "instructions" : "hide"
              }
            >
              <p>
                <InfoIcon />
                <br />
                Must be between 4 to 20 characters.
                <br />
                Must begin with a letter.
                <br />
                Only letters, numbers, underscores, hyphens, dots allowed.
              </p>
            </span>

            <TextField
              fullWidth
              label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              margin="normal"
              required
              multiline
              rows={4}
            ></TextField>
            <Box mt={3}>
              <Button
                mt={3}
                fullWidth
                //className="genericBg"
                type="submit"
                variant="contained"
                sx={{
                  "&:hover": { opacity: 0.5 },
                  bgcolor: "#5ba7d5",
                }}
                onClick={handleSubmit}
                disabled={
                  !validFullName || !validUser || !message ? true : false
                }
              >
                {sendStatus} &nbsp;&nbsp;
                <SendIcon />
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;

/*
<Box display={"block"}
      //flexDirection={"column"}
      //justifyContent={"center"}
      //alignItems={"center"}
      align="center"
      //position="center"
      maxWidth={"70%"}
      m={3}
      sx={{ border: "2px solid #5ba7d5", borderRadius: 2, boxShadow: 5 }}
    >
*/
