import React from "react";
import axios from "axios";
import { Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import Postscard from "../posts_home/Postscard";
import PaginatedPosts from "../pagination/PaginatedPosts";

const PostsAI = () => {
  const [aiPosts, setAiPosts] = useState([]);
  const [muiPaginateCount, setMuiPaginateCount] = useState(0);
  const pageUrlBase = "scienceapi/aiposts";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/scienceapi/aiposts/`
        );
        setAiPosts(resp.data.results);
        //console.log(resp.data);
        let drfPagesizeForPagination = resp.data.results.length;
        //console.log("drfPagesizeForPagination" + drfPagesizeForPagination);
        const paginateCnt = Math.ceil(
          resp.data.count / drfPagesizeForPagination
        );
        setMuiPaginateCount(paginateCnt);
        //console.log("Parent=" + paginateCnt);
        //console.log(resp.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const getPagePosts = (childData) => {
    setAiPosts(childData);
  };

  return (
    <Container justifyContent="center" align="center">
      <Grid
        container
        width="100%"
        direction={"column"}
        spacing={1}
        justifySelf={"center"}
      >
        {aiPosts.map((aiPost, key) => {
          return (
            <Grid item xs>
              <Postscard
                title={aiPost.title}
                excerpt={aiPost.excerpt}
                date_posted={aiPost.date_posted}
                //image={`${process.env.REACT_APP_API_URL}/${aiPost.image}`}
                image={aiPost.image}
                content={{ value: ["AI", <br />, "&", <br />, "Us"] }}
                postHref={`/science/aiandus/details/${aiPost.slug}`}
              />
            </Grid>
          );
        })}
      </Grid>

      {muiPaginateCount > 1 ? (
        <PaginatedPosts
          muiPaginateCount={muiPaginateCount}
          pageUrlBase={pageUrlBase}
          sendDataToParent={getPagePosts}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default PostsAI;
