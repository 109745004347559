import React from "react";
import { Box, Stack } from "@mui/material";
import Sidemenu from "./Sidemenu";
import MainTable from "./MainTable";

const Controller = ({ origin }) => {
  return (
    <Stack display="flex" flexDirection={"row"}>
      <Box flex={1}>
        <Sidemenu />
      </Box>
      <Box flex={6} m={2}>
        <MainTable origin={origin} />
      </Box>
    </Stack>
  );
};

export default Controller;
