import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

const Unauthorized = () => {
  const location = useLocation();
  const errMsg = location.state?.errMsg;

  return (
    <Box
      m={3}
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      //minHeight="100vh"
    >
      <Box
        className={errMsg ? "errmsg" : "hide"}
        display={"flex"}
        flex={6}
        flexDirection={"column"}
        width="50vw"
      >
        <Typography variant="h6" className="errmsg">
          {errMsg}
        </Typography>
      </Box>
    </Box>
  );
};

export default Unauthorized;
