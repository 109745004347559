import { Box, Stack } from "@mui/material";
import React from "react";
import PostsHome from "../posts_home/PostsHome";
import PostsBiology from "../posts_science/PostsBiology";
import PostsAI from "../posts_science/PostsAI";
import Rightbar from "../rightbar/Rightbar";
import PostsFiction from "../posts_fiction/PostsFiction";
import PostsHumor from "../posts_humor/PostsHumor";
import LazyButton from "../lazybutton/LazyButton";

const Main = ({ myorigin }) => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        align={"center"}
        mb={0}
        p={0}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <LazyButton />
      </Box>
      <Stack direction={"row"} spacing={1} mt={{ md: 2, xs: 0 }}>
        {myorigin === "home" ? (
          <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
            <PostsHome />
          </Box>
        ) : myorigin === "science/biologyandus" ? (
          <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
            <PostsBiology />
          </Box>
        ) : myorigin === "science/aiandus" ? (
          <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
            <PostsAI />
          </Box>
        ) : myorigin === "fiction" ? (
          <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
            <PostsFiction />
          </Box>
        ) : myorigin === "humor" ? (
          <Box flex={6} sx={{ display: { xs: "block", md: "flex" } }}>
            <PostsHumor />
          </Box>
        ) : (
          ""
        )}
        <Box flex={3} sx={{ display: { xs: "none", md: "flex" } }}>
          <Rightbar />
        </Box>
      </Stack>
    </>
  );
};

export default Main;

/*

*/
