import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import useAuth from "../../context/useAuth";

const AI = () => {
  const { email } = useAuth();
  const navigate = useNavigate();
  const [aiDetails, setAiDetails] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  let { slug } = useParams();

  /*
    const generateSlug = (string) => {
      let str = string.replace(/^\s+|\s+$/g, "");
      str = str.toLowerCase();
      str = str
        .replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-");
      return str;
    };
    */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/scienceapi/aiposts/${slug}`
        );
        setAiDetails(resp.data);
        setLikeCount(resp.data.like_count);
        //console.log(resp.data);
        //console.log(resp.data.images);
        //console.log(humorDetails);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleLike = async () => {
    if (email) {
      setLikeCount(likeCount + 1);
      try {
        const like_resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/scienceapi/ailikes/${aiDetails.id}`
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/signin");
    }
  };

  return (
    <Grid
      direction={"column"}
      container
      justifyContent={"center"}
      alignItems={"center"}
      align={"justify"}
    >
      <Grid item xs mt={2} justifyContent={"center"}>
        <Avatar
          variant="rounded"
          component="string"
          sx={{ bgcolor: "#5ba7d5", width: { xs: 300, md: 500 }, height: 100 }}
        >
          <Typography
            variant={"h3"}
            align="center"
            sx={{
              color: "black",
              fontFamily: "Splash, cursive",
            }}
          >
            AI & Us
          </Typography>
        </Avatar>
      </Grid>
      <Grid item xs mt={2}>
        <Typography
          variant={"h4"}
          align="center"
          sx={{
            color: "#5ba7d5",
            fontFamily: "Single Day, cursive",
          }}
        >
          {aiDetails.title}
        </Typography>
      </Grid>
      <Grid item xs mt={2}>
        <Typography
          variant={"body1"}
          align="center"
          sx={{
            color: "#5ba7d5",
            fontFamily: "Single Day, cursive",
            fontStyle: "italic",
          }}
        >
          {
            aiDetails?.date_posted
              ? new Date(aiDetails?.date_posted).toDateString()
              : null
            //new Date(aiDetails.date_posted).toDateString()
          }
        </Typography>
      </Grid>
      <Grid item xs width={"inherit"}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"left"}
          alignContent={"center"}
          opacity={0.8}
        >
          <IconButton className={"iconbuttonbg"} onClick={handleLike}>
            <Typography
              p={0.5}
              align={"left"}
              variant="body2"
              display={"flex"}
              sx={{ opacity: 0.8, fontStyle: "italic" }}
            >
              Like
            </Typography>
            <ThumbUpAltIcon />
          </IconButton>
          {likeCount > 0 ? (
            <Typography
              pt={1.5}
              variant="body2"
              display={"flex"}
              sx={{ opacity: 0.8, fontStyle: "italic" }}
            >
              {likeCount}
            </Typography>
          ) : (
            ""
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs
        mt={1}
        width={"inherit"}
        //display={"flex"}
        //align="left"
        //justifyContent={"left"}
      >
        <Typography
          variant={"h6"}
          sx={{
            //color: "#5ba7d5",
            fontFamily: "Single Day, cursive",
            fontStyle: "italic",
          }}
        >
          <ReactMarkdown
            remarkPlugins={[gfm]}
            children={aiDetails.excerpt}
            rehypePlugins={[
              [
                rehypeExternalLinks,
                { target: "_blank", rel: "nofollow noopener noreferrer" },
              ],
            ]}
          ></ReactMarkdown>
        </Typography>
      </Grid>
      <Grid item xs mt={2} id="content1">
        <Typography className={"contenth"} variant={"body1"}>
          <ReactMarkdown
            children={aiDetails.content1}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[
              [
                rehypeExternalLinks,
                { target: "_blank", rel: "nofollow noopener noreferrer" },
              ],
              rehypeRaw,
            ]}
          />
        </Typography>
      </Grid>
      <Grid item xs mt={2}>
        <Card>
          <CardMedia component="Avatar">
            <Avatar
              id="image"
              variant="square"
              src={aiDetails.image}
              sx={{
                bgcolor: "transparent",
                width: { md: 500, xs: 350 },
                height: { md: 500, xs: 350 },
                loading: "lazy",
              }}
            />
          </CardMedia>
          <CardContent>
            <Typography
              variant="body2"
              sx={{ fontSize: "0.9em", width: { md: 500, xs: 350 } }}
            >
              <ReactMarkdown
                remarkPlugins={[gfm]}
                rehypePlugins={[
                  [
                    rehypeExternalLinks,
                    { target: "_blank", rel: "nofollow noopener noreferrer" },
                  ],
                ]}
                children={aiDetails.image_caption}
              ></ReactMarkdown>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs mt={2}>
        <Typography className={"contenth"} variant={"body1"} id="content2">
          <ReactMarkdown
            remarkPlugins={[gfm]}
            rehypePlugins={[
              [
                rehypeExternalLinks,
                { target: "_blank", rel: "nofollow noopener noreferrer" },
              ],
              rehypeRaw,
            ]}
            children={aiDetails.content2}
          ></ReactMarkdown>
        </Typography>
      </Grid>
      {aiDetails.references ? (
        <Grid item xs mt={3} width={"inherit"}>
          <Box>
            <Typography
              id="references"
              className={"contenth"}
              variant={"h6"}
              align="center"
              fontWeight={"bold"}
            >
              References
            </Typography>

            <Typography variant="body2">
              <ReactMarkdown
                remarkPlugins={[gfm]}
                rehypePlugins={[
                  [
                    rehypeExternalLinks,
                    { target: "_blank", rel: "nofollow noopener noreferrer" },
                  ],
                ]}
                children={aiDetails.references}
              ></ReactMarkdown>
            </Typography>
          </Box>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default AI;
