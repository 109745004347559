import React from "react";
import axios from "axios";
import { Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import Postscard from "../posts_home/Postscard";
import PaginatedPosts from "../pagination/PaginatedPosts";

const PostsBiology = () => {
  const [biologyPosts, setBiologyPosts] = useState([]);
  const [muiPaginateCount, setMuiPaginateCount] = useState(0);
  const pageUrlBase = "scienceapi/biologyposts";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/scienceapi/biologyposts/`
        );
        setBiologyPosts(resp.data.results);
        let drfPagesizeForPagination = resp.data.results.length;
        //console.log("drfPagesizeForPagination" + drfPagesizeForPagination);
        const paginateCnt = Math.ceil(
          resp.data.count / drfPagesizeForPagination
        );
        setMuiPaginateCount(paginateCnt);
        //console.log(resp.data);
        //console.log(resp.data.results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const getPagePosts = (childData) => {
    setBiologyPosts(childData);
  };

  return (
    <Container justifyContent="center" align="center">
      <Grid
        container
        width="100%"
        direction={"column"}
        spacing={1}
        justifySelf={"center"}
      >
        {biologyPosts.map((biologyPost, key) => {
          return (
            <Grid item xs>
              <Postscard
                title={biologyPost.title}
                excerpt={biologyPost.excerpt}
                date_posted={biologyPost.date_posted}
                //image={`${process.env.REACT_APP_API_URL}/${biologyPost.image}`}
                image={biologyPost.image}
                content={{ value: ["Biology", <br />, "&", <br />, "Us"] }}
                postHref={`/science/biologyandus/details/${biologyPost.slug}`}
              />
            </Grid>
          );
        })}
      </Grid>

      {muiPaginateCount > 1 ? (
        <PaginatedPosts
          muiPaginateCount={muiPaginateCount}
          pageUrlBase={pageUrlBase}
          sendDataToParent={getPagePosts}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default PostsBiology;
