import { Container, Grid, Typography, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import Postscard from "./Postscard";
import axios from "axios";

const PostsHome = () => {
  const HeadBox = styled(Typography)({
    //marginTop: { md: 40 },
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
    fontFamily: "Single Day, cursive",
    //backgroundColor: "lightblue",
    backgroundColor: "#5ba7d5",
    opacity: 0.7,
  });

  const [biologyPost, setBiologyPost] = useState([]);
  const [aiPost, setAiPost] = useState([]);
  const [humorPost, setHumorPost] = useState([]);
  const [fictionPost, setFictionPost] = useState([]);

  const [featuredPost, setFeaturedPost] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [featuredExcerpt, setFeaturedExcerpt] = useState("");
  const [featuredDetailsHrefBase, setFeaturedDetailsHrefBase] = useState("");

  //fetch the latest Biology post ready for publication at initialization.
  useEffect(() => {
    //define our function inside this

    const fetchDataBiology = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/homeapi/biologyhomepost/`
        );
        setBiologyPost(res.data);
        //setBiologyDate(new Date(res.data.date_posted).getTime());
        //console.log("Biology post=", res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataBiology();
  }, []);

  //fetch the latest AI post ready for publication at initialization.
  useEffect(() => {
    //define our function inside this

    const fetchDataAi = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/homeapi/aihomepost/`
        );
        setAiPost(res.data);
        //setAiDate(new Date(res.data.date_posted).getTime());
        //console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataAi();
  }, []);

  //fetch the latest Humor post ready for publication at initialization.
  useEffect(() => {
    //define our function inside this

    const fetchDataHumor = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/homeapi/humorhomepost/`
        );
        setHumorPost(res.data);
        //setHumorDate(new Date(res.data.date_posted).getTime());
        //console.log(res.data);
      } catch (error) {
        //console.error(error);
        console.log(error);
      }
    };
    fetchDataHumor();
  }, []);

  //fetch the latest Fiction post ready for publication at initialization.
  useEffect(() => {
    //define our function inside this

    const fetchDataFiction = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/homeapi/fictionhomepost/`
        );
        setFictionPost(res.data);
        //setFictionDate(new Date(res.data.date_posted).getTime());
        //console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataFiction();
  }, []);

  useEffect(() => {
    const getFeatured = () => {
      if (biologyPost && aiPost && humorPost && fictionPost) {
        let bio_dt = new Date(biologyPost.date_posted).getTime();
        let ai_dt = new Date(aiPost.date_posted).getTime();
        let humor_dt = new Date(humorPost.date_posted).getTime();
        let fiction_dt = new Date(fictionPost.date_posted).getTime();

        if (ai_dt > bio_dt && ai_dt > humor_dt && ai_dt > fiction_dt) {
          setFeaturedPost(aiPost);
          setFeaturedDetailsHrefBase("/science/aiandus");
        } else if (
          humor_dt > bio_dt &&
          humor_dt > ai_dt &&
          humor_dt > fiction_dt
        ) {
          setFeaturedPost(humorPost);
          setFeaturedDetailsHrefBase("/humor");
          //featuredPost.image = String(humorPost?.first_image?.image);

          //console.log(String(featuredPost.image));
        } else if (
          fiction_dt > bio_dt &&
          fiction_dt > ai_dt &&
          fiction_dt > humor_dt
        ) {
          setFeaturedPost(fictionPost);
          setFeaturedDetailsHrefBase("/fiction");
          //featuredPost.excerpt = fictionPost.summary;
        } else {
          setFeaturedPost(biologyPost);
          setFeaturedDetailsHrefBase("/science/biologyandus");
        }

        featuredPost?.first_image?.image
          ? setFeaturedImage(featuredPost?.first_image?.image)
          : setFeaturedImage(featuredPost.image);
        featuredPost.summary
          ? setFeaturedExcerpt(featuredPost.summary)
          : setFeaturedExcerpt(featuredPost.excerpt);
        //console.log("Featured post=", featuredPost);
      }
    };
    getFeatured();
  }, [biologyPost, aiPost, humorPost, fictionPost, featuredPost]);

  return (
    <Container justifyContent="center" align="center">
      <HeadBox variant="h3" color="green" mt={{ md: 4 }}>
        Featured Post
      </HeadBox>
      <Postscard
        title={featuredPost.title}
        excerpt={featuredExcerpt}
        date_posted={featuredPost.date_posted}
        image={`${process.env.REACT_APP_API_URL}/${featuredImage}`}
        //image={featuredPost.image}
        content={{ value: ["Featured"] }}
        postHref={`${featuredDetailsHrefBase}/details/${featuredPost.slug}`}
      />
      <HeadBox variant="h3" mt={3}>
        Science
      </HeadBox>
      <Grid
        container
        width="100%"
        direction="row"
        spacing={1}
        justifySelf={"center"}
      >
        <Grid item xs>
          <Postscard
            title={biologyPost.title}
            excerpt={biologyPost.excerpt}
            date_posted={biologyPost.date_posted}
            image={`${process.env.REACT_APP_API_URL}/${biologyPost.image}`}
            content={{ value: ["Biology", <br />, "&", <br />, "Us"] }}
            postHref={`/science/biologyandus/details/${biologyPost.slug}`}
          />
        </Grid>
        <Grid item xs>
          <Postscard
            title={aiPost.title}
            excerpt={aiPost.excerpt}
            date_posted={aiPost.date_posted}
            image={`${process.env.REACT_APP_API_URL}/${aiPost.image}`}
            //image={aiPost.image}
            content={{ value: ["AI", <br />, "&", <br />, "Us"] }}
            postHref={`/science/aiandus/details/${aiPost.slug}`}
          />
        </Grid>
      </Grid>

      <HeadBox variant="h3" mt={3}>
        Fiction
      </HeadBox>
      <Grid container width="100%" direction="column" justifySelf={"center"}>
        <Grid item xs>
          <Postscard
            title={fictionPost.title}
            excerpt={fictionPost.summary}
            date_posted={fictionPost.date_posted}
            image={`${process.env.REACT_APP_API_URL}/${fictionPost.image}`}
            //image={fictionPost.image}
            content={{ value: ["Fiction"] }}
            postHref={`/fiction/details/${fictionPost.slug}`}
          />
        </Grid>
      </Grid>
      <HeadBox variant="h3" mt={3}>
        Humo(u?)r
      </HeadBox>
      <Grid container width="100%" direction="column" justifySelf={"center"}>
        <Grid item xs>
          <Postscard
            content={{ value: ["Humor"] }}
            title={humorPost.title}
            excerpt={humorPost.excerpt}
            date_posted={humorPost.date_posted}
            image={`${process.env.REACT_APP_API_URL}/${humorPost?.first_image?.image}`}
            postHref={`/humor/details/${humorPost.slug}`}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PostsHome;
/*
 <HeadBox variant="h3">Featured</HeadBox>
      <PostsFeatured
        biologyPost={biologyPost}
        aiPost={aiPost}
        humorPost={humorPost}
        fictionPost={fictionPost}
      />

<Typography
        variant="h3"
        textAlign="center"
        alignContent={"center"}
        justifyContent={"center"}
        sx={{
          //fontFamily: "Splash, cursive",
          fontFamily: "Single Day, cursive",
        }}
      >
        Science
      </Typography>
*/
