import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { EMAIL_REGEX } from "../../utils/UsedProps";

const ResetPwdLinkSend = () => {
  const initializeData = Object.freeze({
    email: "",
  });

  const [formData, setFormData] = useState(initializeData);

  const [validUser, setValidUser] = useState(false);
  //whether we set focus on the email field or not
  const [userFocus, setUserFocus] = useState(false);

  const [emailFocus, setEmailFocus] = useState(false);
  const [buttonText, setButtonText] = useState(
    "Send reset password link to your email id"
  );
  const [sending, setSending] = useState(false);

  const [errorData, setErrorData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let user = formData.email;
    const result = EMAIL_REGEX.test(user);
    //console.log(result);
    //console.log(user);
    setValidUser(result);
  }, [formData.email]);

  const handleChange = (e) => {
    e.preventDefault();

    setFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const createNew = async (e) => {
    const form = document.querySelector("form");
    //alert("Hii");
    e.preventDefault();
    let formData = new FormData(form);
    let data = {};
    data["email"] = formData.get("email");
    data["urlbase"] = process.env.REACT_APP_PUBLIC_URL;
    //console.log("Data=", data);

    setSending(true);
    setButtonText("Sending...");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/userapi/reset_password`,
        data,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );

      setSuccessMsg(
        "An email with a link to reset your password has been sent to " +
          formData.get("email")
      );
      setErrorMsg("");
      setSending(false);
      setButtonText("Send reset password link to your email id");
    } catch (error) {
      let err = "The email to reset password could not be sent.";
      if (error.response.data !== null) {
        //error.response.data["detail"] ? setErrorData(error.response.data)["detail"] : "";
        err = error.response.data["detail"]
          ? "User account does not exist for this email id!"
          : err;
        //console.log(JSON.stringify(error.response.data["detail"]));
      }
      setErrorMsg(err);
      setSuccessMsg("");
      setSending(false);
      setButtonText("Send reset password link to your email id");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      createNew(e);
    } catch (error) {
      setErrorMsg("The email to reset password could not be sent.");
      console.log(error);
    }
  };

  return (
    <>
      <div style={{ marginTop: 4 }} className={errorMsg ? "errmsg" : "hide"}>
        <span>{errorMsg}</span>
      </div>

      <div className={successMsg ? "successmsg" : "hide"}>
        <span>{successMsg}</span>
      </div>
      <Box
        m={3}
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box display={"flex"} flex={6} flexDirection={"column"} width="50vw">
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              flex={6}
              variant="h6"
              justifyContent={"center"}
              align="center"
            >
              Reset password
            </Typography>
          </Box>

          <Box display={"block"} spacing={1} m={2} fullWidth>
            <Typography
              align={"center"}
              justifyContent={"center"}
              variant="body1"
            >
              Please enter the email id associated with your account. A password
              reset link will be sent to this email id if an account exists.
            </Typography>
            <form id="createForm" onSubmit={handleSubmit} width="inherit">
              <Box width="inherit">
                <div className={errorData.email ? "errdetail" : "hide"}>
                  <span>{errorData.email}</span>
                </div>

                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  margin="normal"
                  required
                  onChange={handleChange}
                  onFocus={() => setEmailFocus(true)}
                  onBlur={() => setEmailFocus(false)}
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </Box>

              <Box mt={3} justifyContent={"center"} align={"center"}>
                <Button
                  mt={3}
                  //fullWidth

                  type="submit"
                  variant="contained"
                  sx={{
                    "&hover": { opacity: 0.8, "&hover": `all 0.5s ease` },
                    //bgcolor: "#9dc6d8",
                    bgcolor: "#5ba7d5",
                    textTransform: "None",
                  }}
                  onClick={handleSubmit}
                  disabled={sending || !validUser ? true : false}
                >
                  {buttonText}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPwdLinkSend;
