import { ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useState } from "react";
import { menu_science } from "../../utils/UsedProps";
import { NavLink } from "react-router-dom";

const MenuxsAccount = () => {
  const [anchorSubEl, setAnchorSubEl] = useState(null);
  const handleOpenSubMenu = (event) => {
    setAnchorSubEl(event.currentTarget);
  };

  const handleCloseSubMenu = (e) => {
    setAnchorSubEl(null);
    e.currentTarget = null;
  };

  return (
    <>
      {menu_science.map((item) => {
        return (
          <MenuItem
            key={item.Name}

            //onBlur={handleCloseSubMenu}
          >
            <ListItemText
              primary={item.Name}
              onClick={handleOpenSubMenu}
              onClose={handleCloseSubMenu}
            />

            <Menu
              id="submenu_science_xs"
              anchorEl={anchorSubEl}
              open={Boolean(anchorSubEl)}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "center", horizontal: "left" }}
              onClose={handleCloseSubMenu}
              //onBlur={handleCloseSubMenu}
            >
              {item.submenu.map((submnu) => {
                return (
                  <MenuItem
                    component={NavLink}
                    to={submnu.url}
                    onClick={(e) => handleCloseSubMenu()}
                  >
                    <ListItemText primary={submnu.Name}></ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </MenuItem>
        );
      })}
    </>
  );
};

export default MenuxsAccount;
